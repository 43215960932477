.dashboard {
  .graph {
    position: absolute;
    bottom: 20px;
  }
  .days {
    font-size: 30px;
    font-weight: 300;
    color: $cobalt-blue;
  }
  .cardHeader {
    height: 50px;
    display: table-cell;
    vertical-align: middle;
    @media #{$medium-and-down} {
      padding: 3px 8px!important;
    }
    @media #{$medium-and-up} {
      padding: 3px 24px!important;
    }
    .title {
      font-size: 17px;
      @media #{$medium-and-up} {
        font-size: 19px;
      }
      letter-spacing: 0.4px;
      text-align: left;
      color: $dark-blue;
    }
    .chevron {
      position: absolute;
      top: 12px;
      @media #{$medium-and-down} {
        right: 8px;
      }
      @media #{$medium-and-up} {
        right: 24px;
      }
    }
  }
  .card-content {
    position: relative;
    height: 136px;
    background-color: $off-white;
    color: $dark-blue;
    @media #{$medium-and-down} {
      padding: 8px!important;
    }
    @media #{$medium-and-up} {
      padding: 24px!important;
    }
    .name {
      font-size: 16px;
    }
    .date-container {
      display: flex;
      justify-content: space-between;
    }
    .arrow-container {
      display: flex;
      justify-content: center;
      align-self: center;
      position: relative;
      width: 100%;
      padding: 3px;
      margin-top: 12px;
      div {
        margin: 0px 5px 0px;
      }
      .status {
        font-size: 13px;
      }
      .line {
        border-bottom: 2px solid #d6d6d6;
        width: 40%;
        margin: 0 0 8px;
      }
      .line-chevron {
        margin-left: -6px!important;
        margin-right: 0px!important;
        padding-top: 0px;
        height: 16px;
      }
    }
    .date-container-l, .date-container-r {
      position: relative;
      .date-day {
        top: 20px;
        z-index: 300;
        font-size: 36px;
        font-weight: 400;
        color: $cobalt-blue;
        position: absolute;
      }
      .date-month {
        font-size: 10px;
        position: absolute;
        bottom: 5px;
      }
      .date-day, .date-month {
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .performance {
    .percentage {
      div {
        font-size: 30px;
        font-weight: 300;
        color: $cobalt-blue;
      }
    }
  }
  .reviews {
    .stars {
      margin-top: -10px;
    }
    .date-review {
      padding-top: 10px;
      font-size: 13px;
    }
  }
  .great-host-guide {
    //margin: -24px 0px;
    .content-el {
      height: 40px;
      border-top: 1px solid #efefef;
      justify-content: space-between;
      color: $dark-blue;
    }
    a:nth-of-type(1) {
      border-top: none;
    }
  }
  .higher-card {
    .card-content {
      min-height: 136px;
      height: 100%;
    }
  }
  .service-log {
    .last-service {
      border-bottom: 1px solid #efefef;
    }
    .service-icon {
      margin: 0 5px;
    }
    .service-txt {
      margin-bottom: 23px;
      padding-bottom: 3px;
      border-bottom: 1px solid #efefef;
    }
  }
}
