.onboarding_checklist {
	width: 100%;

	&__information {
		display: grid;
		justify-content: center;

		* {
			max-width: 720px;
		}
	}

	&__form {
		#onboarding_checklist_body {
			display: flex;
			justify-content: center;
			margin-top: 15px;
		}
	}

	h2 {
		margin: 0;
	}

	&__section {
		width: 100%;
		max-width: 720px;

		.input-field {
			margin-bottom: 0;

			@media screen and (min-width:601px) {
				.select-wrapper {
					width: 50%;

					.select-dropdown {
						width: 100%;
					}
				}

				.trumbowyg-box {
					width: 75%;
				}

				&.has_suffix_icon {
					width: unset;

					>label {
						white-space: nowrap;
					}
				}

				&--small:is(input) {
					width: 12.5%;
				}

				&--medium:is(input) {
					width: 25%;
				}

				&--large:is(input) {
					width: 40%;
				}
			}

			label,
			input {
				color: $dark-blue;
			}

			div {
				p {
					&:first-of-type {
						margin-top: 0;
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}

			.direct_debit {
				>div>div {
					justify-content: center;

					@media screen and (min-width:601px) {
						justify-content: flex-start;
					}
				}
			}

			>i:not(.status_spinner) {
				cursor: pointer;
			}

			.preloader-wrapper.suffix,
			.status_spinner.suffix {
				width: 36px;
				top: 0.25rem;
				right: 0.75rem;
			}

			&.rich_text_field {
				margin: 0;

				label {
					position: initial;
				}

				.trumbowyg-box {
					margin-top: 5px;
				}

				.preloader-wrapper.suffix {
					top: 1.5rem;
				}

				.status_spinner.suffix {
					top: 1.75rem;
				}
			}

			.status_spinner {
				// The animation time should match the timeout duration where setSpinnerStatus(null) is called (in generic_field)
				animation: spinnerFadeInOut 1s forwards;

				&.success {
					color: $asparagus;
				}

				&.failure {
					color: $rusty-red;
				}

				@keyframes spinnerFadeInOut {
					0% {
						opacity: 0;
					}

					50% {
						opacity: 1;
					}

					100% {
						opacity: 0;
					}
				}
			}

			// To ensure the labels don't overflow and cause unnecessary scrolling
			input[type=text]:not(.browser-default).validate+label {
				width: auto;
			}
		}

		.beds {
			display: flex;
			flex-direction: column;

			h5 {
				margin-top: 0;
			}

			>span:last-of-type {
				margin-top: 20px;
			}

			.bed {
				display: flex;
				align-items: center;

				&>i {
					cursor: pointer;
					color: $rusty-red;
					margin-right: 20px;
				}

				&>.select-wrapper {
					width: 100%;

					@media screen and (min-width:601px) {
						width: unset;
					}
				}
			}

			>button {
				align-self: center;
				min-width: 30%;
				max-width: 50%;
				margin-top: 10px;

				@media screen and (min-width:601px) {
					align-self: flex-start;
				}
			}
		}

		.documents {
			&__number {
				display: flex;
				flex-direction: column;
			}

			&__dates {
				display: flex;

				>div:first-child {
					margin-right: 15px;
				}

				input {
					width: 100%;
				}
			}

			&__files {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 10px;
				align-items: center;

				>.documents__files_new,
				>.documents__files_done {
					position: relative;
					transition: border-radius 1s;
					margin-top: 10px;
					margin-right: 15px;
				}

				.btn-floating {
					transition: all .3s ease-out;
				}

				&_new {
					background-color: transparent;
					width: 150px;
					height: 150px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 20px;
					flex-direction: column;

					>i {
						background-color: $cobalt-blue;
						border-radius: 100%;
						width: 40px;
						height: 40px;
						margin-top: 20px;

						&.icon_centered {
							line-height: 54px;

							.spinner-blue-only {
								border-color: $cobalt-blue;
							}
						}
					}

					>p {
						color: $dark-blue;
					}
				}

				&_done {
					cursor: default;
					border-radius: 20px;
					width: 150px;
					height: 150px;
					background-color: transparent;

					>i,
					>a>i {
						cursor: pointer;
						background-color: $cobalt-blue;
						border-radius: 10px;
						font-size: 30px;
						position: absolute;
						width: 30px;
						height: 30px;
						line-height: 30px;
					}

					>i:first-of-type {
						top: 10px;
						left: 10px;
						background-color: $rusty-red;
					}

					>a>i:first-of-type {
						bottom: 10px;
						right: 10px;
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				.modal {
					border-radius: 20px;

					&-content {
						background-color: white;

						>div {
							background-color: white;
						}
					}

					&__header {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}

					.file-name {
						overflow: auto;
					}

					.left-arrow,
					.right-arrow {
						background-color: transparent;
						border-color: transparent;
						cursor: pointer;
						padding: 6px;

						&:disabled {
							cursor: not-allowed;

							>i {
								color: lightgray;
							}
						}

						>i {
							font-size: 40px;
							color: $cobalt-blue;
						}
					}
				}
			}
		}

		.section_submit_button {
			width: 100%;
			margin-top: 20px;
			text-align: center;

			@media #{$medium-and-up} {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			>.error_container {
				margin-bottom: 10px;

				>.error {
					display: block;
				}
			}
		}

		span.error_text {
			display: block;
			position: relative;
			color: red;
			min-height: 18px;
			font-size: 12px;
		}
	}

	&__group {
		margin-top: 10px;

		>p {
			color: $grey;
		}

		&_wrapper {
			&:first-of-type {
				margin-top: 10px;
			}

			>.dropdown {
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: $off-white;
				padding: 10px;
				box-shadow: 0px 2px 3px 0px #00000040;
				cursor: pointer;

				>svg {
					transition: .2s;
				}
			}

			&--active {
				>.dropdown {
					>svg {
						transform: rotate(-90deg);
					}
				}
			}
		}
	}

	&__field {}

	.scale_active_label {
		transform: scale(0.8);
		transform-origin: 0 0;
	}

	// double class ensures priority
	.font_active_label.font_active_label {
		font-size: 0.8rem; // needs to be specific because some labels act weird when scaled; e.g the rich text labels
	}

	.select-wrapper>input {
		color: $dark-blue;
	}

	.row {
		margin-bottom: 15px;

		// Creates space between a collasped group followed by a field
		&:not(:first-of-type) {
			margin-top: 15px;
		}
	}

	.valign-wrapper {
		justify-content: center;

		>ul {
			width: auto;
		}
	}

	.tabs {
		&:is(ul) {
			box-shadow: unset;
		}

		.tab a:focus,
		.tab a:focus.active {
			background-color: transparent;
			color: $cobalt-blue;
		}
	}

	#modalChecklistComplete {
		max-width: 450px;

		div.valign-wrapper {
			margin: 30px 0;
			justify-content: flex-end;

			.cancelS {
				margin-right: 20px;
			}
		}

		div>p {
			text-align: center;
		}

		div.valign-wrapper {
			justify-content: space-evenly;
		}
	}

	.key-container {
		position: relative;
		margin: 5px auto 10px;
		min-width: 20px; // To keep the toggle arrow in the same position

		@media screen and (max-width:600px) {
			flex-wrap: wrap;

			&__colour-key>div:not(.key-container__toggle) {
				width: 45%;

				&:first-of-type {
					margin-bottom: 10px;
				}

				&:last-of-type {
					margin-top: 10px;
					margin-right: 45%;
				}

				span {
					width: 100px;
				}
			}
		}

		&__colour-key {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 10px;

			@media screen and (min-width:601px) {
				justify-content: space-evenly;
			}

			>div:not(.key-container__toggle) {

				&.mobile-left,
				&.mobile-right {
					display: flex;
					justify-content: center;
				}

				span {
					margin-left: 10px;
				}
			}
		}

		>div {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		.colour-key {
			width: 22px;
			height: 22px;
			font-size: .9rem;
			border-radius: 6px;

			span {
				margin-left: 30px;
			}
		}

		&__active-section {
			background-color: $cobalt-blue;
			border: 1px solid $cobalt-blue;
		}

		&__not-started {
			background-color: $dark-blue;
			border: 1px solid $dark-blue;
		}

		&__in-progress {
			background-color: $squash;
			border: 1px solid $squash;
		}

		&__complete {
			background-color: $asparagus;
			border: 1px solid $asparagus;
		}

		&__errors {
			background-color: $rusty-red;
			border: 1px solid $rusty-red;
		}
	}
}

// Opposite of default materialize prefix (for icons)
.input-field .suffix {
	position: absolute;
	width: 3rem;
	font-size: 2rem;
	-webkit-transition: color .2s;
	transition: color .2s;
	top: .5rem;
	right: 0;

	&~input,
	~label,
	~textarea,
	~.helper-text,
	~.autocomplete-content {
		margin-right: 3rem;
		width: 92%;
		width: calc(100% - 3rem);
	}

	&~label,
	&~.validate~label {
		width: calc(100% - 3rem - 1.5rem);
	}
}


// the data-status bit is duplicated to ensure css priority
[data-status="not_started"][data-status="not_started"]>a {
	color: $dark-blue;
}

[data-status="incomplete"][data-status="incomplete"]>a {
	color: $squash;
}

[data-status="errors"][data-status="errors"]>a {
	color: $rusty-red;
}

[data-status="complete"][data-status="complete"]>a {
	color: $asparagus;
}
