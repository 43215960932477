.profile {
	hr {
		border: 0;
		border-top: 1px solid #ebebeb;
		margin-top: 30px;
	}

	padding-bottom: 25px;

	.fix-display {
		min-height: 52px;

		#direct-debit {
			position: relative;
			width: 100%;
			padding-top: 10px;
			padding-bottom: 10px;
			line-height: 1;

			.progress {
				top: 0;
				bottom: 0;
				margin: auto;
				position: absolute;
			}

			.failed div {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.status {
					flex: 2;
				}
			}
		}

		#direct-debit:hover,
		#direct-debit:hover .material-icons {
			color: $squash;
		}
	}

	#client_preferences {
		#preferred_language {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			p {
				flex: 1 0 50%;

				&.description {
					color: $grey;
					margin: 0;
					flex: 1 0 100%;
				}
			}

			select {
				-webkit-appearance: none;
				cursor: pointer;
				color: $cobalt-blue;
				margin: 0;
				height: 100%;
				text-align: center;
				border: solid 1px;
				flex: 1 0 50%;

				&:hover {
					color: $squash;
				}

				&:disabled {
					cursor: not-allowed;
					color: $cobalt-blue;
				}
			}


			@media #{$medium-and-up} {
				p {
					flex: 1 0 20%;
				}

				select {
					flex: 1 0 80%;
				}
			}
		}
	}

	&__buttons {

		>div,
		>button {
			max-width: 50%;
			width: fit-content;
			height: fit-content;
		}
	}

	.detailed_list {
		&>div {
			display: flex;

			&>div {
				padding: 0;
				padding-bottom: 15px;
				width: 44%;

				&:first-of-type {
					padding-right: 15px;
				}

				&:last-of-type {
					padding-left: 15px;
				}
			}
		}

		.last_row>div {
			padding-bottom: 0;
		}
	}
}

.contact-us,
.profile {
	div.s3 {
		margin-top: 14px;
	}

	h5,
	h6 {
		word-wrap: break-word;
	}

	.img-container {
		height: 178px;
		width: 178px;
		text-align: left;
		border-radius: 6px;
		border: 1px solid #ebebeb;
		overflow: hidden;
	}

	.dd {
		border-bottom: 1px solid #ebebeb;
		border-top: 1px solid #ebebeb;
		margin-top: 0;
		box-shadow: none;
		border-left: 0;
		border-right: 0;
		cursor: pointer;

		&:hover,
		&:active {
			box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15);
			color: $squash;

			path {
				fill: $squash;
			}

			.collapsible-body {
				color: $dark-blue;
			}
		}

		li {
			width: 100%;

			.collapsible-header,
			.collapsible-body {
				padding: 10px 0 10px;
			}

			.collapsible-header {
				border-bottom: none;
			}

			.collapsible-body {
				border-top: 1px solid #ebebeb;
				border-bottom: none;
			}
		}

		.acc-end {
			display: flex;

			span {
				margin-right: 5px;
				line-height: 2.5;
			}
		}
	}

	.email,
	.call,
	.address {
		line-height: 1;
		padding-bottom: 10px;
		box-shadow: 0 1px 0 0 #ebebeb;
	}

	.email p,
	.address p,
	.call p,
	.open-tc p,
	.dd p {
		max-width: 270px;
		word-wrap: break-word;
		text-align: right;
		line-height: 2;
		margin: 0;
	}

	hr {
		border: 0;
		border-top: 1px solid #ebebeb;
		margin-top: 30px;
	}

	padding-bottom: 25px;

	.open-tc {
		width: 100%;
		border: none;
		background-color: transparent;
		cursor: pointer;
		color: $dark-blue;

		&:hover,
		&:active {
			color: $squash;

			path {
				fill: $squash !important;
			}
		}
	}

	.pos-rel {
		position: relative;
	}
}

.contact-us,
.profile {
	div.s3 {
		margin-top: 14px;
	}

	h5,
	h6 {
		word-wrap: break-word;
	}

	.img-container {
		height: 178px;
		width: 178px;
		text-align: left;
		border-radius: 6px;
		border: 1px solid #ebebeb;
		overflow: hidden;
	}

	.email,
	.call,
	.address {
		line-height: 1;
		padding-bottom: 10px;
		box-shadow: 0 1px 0 0 #ebebeb;
	}

	.email p,
	.address p,
	.call p,
	.open-tc p {
		max-width: 270px;
		word-wrap: break-word;
		text-align: right;
		line-height: 2;
		margin: 0;
	}
}

.feedback {
	padding-top: 0px;

	h5 {
		font-size: 24px;
		font-weight: bold;
		letter-spacing: -0.1px;
		color: $dark-blue;
	}

	.happy-container,
	.unhappy-container {
		cursor: pointer;
		width: 100%;
		height: 164px;
		border-radius: 6px;
		overflow: hidden;
		border: 1px solid #efefef;
		position: relative;

		.face-container {
			display: flex;
			justify-content: space-around;
			height: 110px;
		}

		.happy-face,
		.unhappy-face {
			position: absolute;
			top: 34%;
			left: 50%;
			margin-right: -50%;
			transform: translate(-50%, -50%);
		}

		div.valign-wrapper {
			justify-content: space-between;
			height: 50px;
			padding: 0 15px;
		}
	}

	.happy-container .face-container {
		background-color: #769646;
	}

	.unhappy-container .face-container {
		background-color: #9c1612;
	}
}

.information {
	hr {
		border: 0;
		border-top: 1px solid #ebebeb;

		@media #{$medium-and-up} {
			margin-top: 50px;
		}
	}

	.package-container,
	.agreement-container {
		width: 100%;
		height: 164px;
		border-radius: 6px;
		background-color: #ffffff;
		border: solid 1px #efefef;

		.top-box {
			width: 100%;
			height: 109px;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			color: $white;
		}

		.bottom-box {
			p {
				position: relative;

				span {
					position: absolute;
					right: 0;
				}
			}
		}
	}

	.package-container {
		cursor: pointer;

		.top-box {
			background-color: #87ad51;

			div {
				margin-top: 15px;
				font-size: 20px;

				@media #{$medium-and-up} {
					font-size: 35px;
				}

				font-weight: 300;
			}
		}
	}

	.agreement-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: auto;
		border: none;
		margin-top: 25px;
		height: auto;

		.thead-box {
			width: 100%;
			height: auto;
			background-color: rgb(7, 29, 181);
			color: white;

			.icon-agreement {
				display: block;
				margin: 0 auto;

				svg {
					float: left;
					margin-right: -30px;
				}

				span {
					height: 38px;
					vertical-align: middle;
					display: table-cell;
				}
			}
		}

		.table-box {
			@media (max-width: 1018px) {
				margin-bottom: 35px;
				max-width: 100%;
			}

			min-width: fit-content;
			max-width: 48%;
			margin-bottom: 50px;

			td,
			th {
				padding-left: 15px;
				padding-right: 15px;
			}

			td:first-child {
				border-right: 1px solid rgba(0, 0, 0, 0.12);

				@media #{$small-and-down} {
					width: 50%;
				}

				@media #{$medium-and-down} {
					width: 35%;
				}

				@media #{$medium-and-up} {
					width: 44%;
				}
			}

			tr:last-child {
				border-bottom: none;
			}
		}

		.table-box.one {
			max-width: 100%;

			td:first-child {
				width: 50%;

				@media #{$medium-and-up} {
					width: 25%;
				}

				@media #{$large-and-up} {
					width: 22%;
				}

				@media #{$extra-large-and-up} {
					width: 20%;
				}
			}
		}
	}
}

.logout-btn.btn {
	width: 100%;

	@media #{$medium-and-up} {
		width: 82px;
	}

	height: 36px;
	border-radius: 6px;
	background-color: #071db5;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 13px;
	text-transform: capitalize;
}

.profile .img-container {
	width: 154px;
	height: inherit;
}

// override input color
/* label color */
#textarea.materialize-textarea label {
	color: #071db5;
}

#textarea.materialize-textarea {
	color: $dark-blue;
}

/* label focus color */
#textarea.materialize-textarea:focus+label {
	color: #071db5;
}

/* label underline focus color */
#textarea.materialize-textarea:focus {
	border-bottom: 1px solid #071db5;
	box-shadow: 0 1px 0 0#071db5;
	color: $dark-blue;
}

/* valid color */
#textarea.materialize-textarea.valid {
	border-bottom: 1px solid #071db5;
	box-shadow: 0 1px 0 0 #071db5;
}

/* invalid color */
#textarea.materialize-textarea.invalid {
	border-bottom: 1px solid #071db5;
	box-shadow: 0 1px 0 0 #071db5;
}

#sendFeedback.btn-flat {
	color: white;
	text-transform: capitalize;
}

.modal .modal-footer {
	text-align: center;
}

.changepwlink,
.viewTC {
	float: right;
	margin-top: 10px;
	cursor: pointer;

	&:focus,
	&:active {
		background-color: #eeeeee;
		border: 1px solid #ccc;
	}

	&.in_key_terms {
		position: absolute;
		right: 0.75rem;
		top: 0;
	}
}
