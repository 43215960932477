.performance {

  .statics {
    display: flex;
    width: 95%;
    margin: 5px auto;

    .occupancy{
      width: 30%;
    }
    .net-revenue{
      width: 38%;
    }
    .review{
      width: 27%;
    }
    .elements{
      padding: 10px;
      box-sizing: border-box;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      border-radius: 6px;
      display: flex;
      margin: 5px;

      @media #{$medium-and-up} {
        margin: 10px;
        width: 33%;
      }
      .left-elements {
        width: 100%;
        text-align: center;
        padding-left: 0;

        @media #{$medium-and-up} {
          width: 50%;
          height: 100%;
          overflow: hidden;
          padding-left: 10px;
        }

        .title{
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: #2D2D2D;
          @media #{$medium-and-up} {
            font-size: 16px;
          }
        }

        .value {
          font-weight: 700;
          line-height: 50px;
          width: 100%;
          color: #0826AE;
          height: fit-content;
          display: block;
          font-size: 26px;

          @media only screen and (min-width: 992px) and (max-width: 1400px) {
            font-size: 32px;
          }
        }

        .net {
          color: #86AE4B;
        }

        .review {
          width: 50%;
          margin: 0 auto;
        }
      }

      .left-mobile {
        width: 40%;
        border-right: 1px solid rgba(235, 235, 235, 0.8);
      }

      .right-elements {
        width: 50%;
        height: 100%;
        padding: 10px;
        p {
          margin-top: 0px;
          font-weight: 400;
          font-size: 10px;
          line-height: 13px;
          color: #4D4D4D; }
      }

      .right-mobile {
        padding: 20px 10px;
            width: 60%;
      }
    }
    .fullWidth{
      width: 97.5%;
      margin: 5px auto;
      height: fit-content;
    }
    .hide-block {
      display: none;
    }
  }
  #forecast-graph {
    @media #{$large-and-up} {
      margin-top: 35px;
    }
    .chart-card {
      margin-bottom: 0px;
      @media #{$large-and-up} {
        margin-bottom: 30px;
      }
    }
  }
  .info-btn {
    width: 75px;
    height: 21px;
    background: #BEBEBE;
    border-radius: 3px;
    padding-left: 8px;
    padding-right: 8px;
    text-transform: capitalize;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 10px;

    @media #{$medium-and-up} {
      width: 130px;
    }
  }
  .month-picker {
    width: 90%;
    margin: 0 auto;
    @media #{$extra-large-and-up} {
      width: 75%;
      margin-right: 24%;
    }
  }

  .summary {
    display: flex;
    justify-content: center;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 35px;

    @media #{$extra-large-and-up} {
      justify-content: flex-end;
      margin-top: -36px;

    }

    .btn-flat {
      text-transform: none;
      width: 160px;
      border-radius: 6px;
      color: $white;
      background-color: $cobalt-blue;
      display: flex;
      justify-content: center;
    }
  }
  .report {
    display: flex;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #efefef;
    .btn-flat {
      width: 65px;
      height: 35px;
      border-radius: 6px;
      color: $white;
      background-color: $cobalt-blue;
      justify-content: center;
      display: flex;
    }
  }
  p {
    margin-top: 0px;
  }
  .value {
    width: 140px;
    height: 92px;
    font-size: 70px;
    color: $cobalt-blue;
    position: relative;
    span {
      position: absolute;
      top: -15px;
      right: 10px;
      @media #{$medium-and-up} {
        top: -15px;
        right: 20px;
      }
    }
  }
  .shadow {
    box-shadow: 0 1px 0 0 #ebebeb;
  }

  // override month picker plugin style
  #month_picker {
    margin-top: 10px;
    @media #{$medium-and-up} {
      margin-top: 22px;
    }
  }

  input[type=text] {
    text-align: center!important;
  }

  input[type=text]:not(.browser-default):focus:not([readonly]) {
    border-bottom: 1px solid $cobalt-blue!important;
    box-shadow: 0 1px 0 0 $cobalt-blue!important;
  }

  .calendar-container {
    width: 100%;
    @media #{$large-and-up} {
      width: 273px;
    }
  }

  .form-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    @media #{$medium-and-up} {
      float: right;
    }

    * {
      cursor: pointer;
    }
    span.p-calendar {
      height: 30px;
    }
    span.text-label {
      color: $cobalt-blue;
      font-size: 1.1rem;
      vertical-align: text-top;
    }
    .month_year_dropdown {
      border-color: transparent;
      border-radius: 8px;
      padding: 6px;
    }
    >* {
      margin-left: 10px;
    }

    >*:first-child {
      margin-left: unset;
    }

    &:hover {
      .month_year_dropdown {
        background-color: white;
        color: $squash;
      }
    }
  }

  .plugin-container {
    position: relative;
    width: 100%;
    @media #{$large-and-up} {
      width: 273px;
    }
    span {
      position: absolute;
      left: 0;
      font-size: 17px;
      bottom: 20px;
      background-color: white;
    }
  }

  input#month_input {
    color: $dark-blue;
    border-bottom: 1px solid $dark-blue;
    text-align: center;
    width: 100%;
    @media #{$large-and-up} {
      width: 273px;
    }
  }

  .col_mp:hover{
    background-color: rgba(7, 29, 181, 0.9);
  }

  .arrows_mp {
    color: $cobalt-blue;
  }

  .arrows_mp:hover, .selected_date_mp:hover {
    background-color: transparent;
  }

  .selected_date_mp{
    color:$cobalt-blue;
    font-size: 18px;
  }
  .selected_cell{
    background-color: $cobalt-blue;
    font-style: normal;
    font-weight: bold;
  }
}

.spinner {
  height: 400px!important;
}

#reportDoc {
  margin: 30px auto 30px;
  max-width: 686px;
  padding-top: 10px;

  #header_content {
    display: none;
  }

  section {
    max-width: 100%;
    overflow-x: scroll;
  }

  .border-b {
    border-bottom: 1px solid #000000;
  }

  .summary-body {
    padding: 8pt 0 8pt;
  }
  .table-title {
    padding: 7pt 11pt 7pt;
  }
  h4 {
    font-size: 14px;
    margin: 3px 0px;
  }
  tr {
    border-bottom: none;
  }
  td {
    padding: 2pt 4px 2pt;
  }
  #footer_content {
    display: none;
  }
  thead, tbody, .larger-font {
    font-size: 12px;
  }
}

#printBT {
  float: right;
  margin-right: 10.5px;
}
