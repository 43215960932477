.not-found {

  .logo-container {
    margin: auto;
    background-image: url("./logo/not-found-logo.jpg");
    background-position: 50% 0%;
    background-size: contain;
    background-repeat: no-repeat;
    height: 170px;
    float: none;
  }

  h1 {
    font-size: 30px;
  }

  h3 {
    font-size: 18px;
  }

  h1,
  h3 {
    color: #4c5169;
    text-align: center;
  }

  img {
    width: 700px;
  }

  @media (max-width: 425px) {
    .logo-container {
      height: 100px;
      background-image: url("./logo/not-found-logo.jpg");
    }
    h1 {
      font-size: 20px;
    }
    h3 {
      font-size: 16px;
    }
    img {
      width: 310px;
    }
  }

  @media (min-width: 426px) and (max-width: 768px) {
    .logo-container {
      height: 150px;
      background-image: url("./logo/not-found-logo.jpg");
    }
    h1 {
      font-size: 22px;
    }
    h3 {
      font-size: 17px;
    }
    img {
      width: 400px;
    }
  }
}
