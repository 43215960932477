.checklist {
	.link {
		text-decoration: underline;
		color: $dark-blue;
	}
	#modalInfoSection {
		overflow-y: auto;
	}
	#modalInfoSection,
	#modalChecklistComplete {
		div.valign-wrapper {
			margin: 30px 0;
			justify-content: flex-end;
			.cancelS {
				margin-right: 20px;
			}
		}
	}
	#modalChecklistComplete {
		max-width: 450px;
		div > p {
			text-align: center;
		}
		div.valign-wrapper {
			justify-content: space-evenly;
		}
	}
	.show {
		display: block !important;
	}
	.hide {
		display: none !important;
	}
	h5 {
		margin-bottom: 10px;
		margin-top: 36px;
	}
	p.title {
		margin-bottom: 0;
		margin-top: 0;
		height: inherit;
	}
	.input-field {
		margin-top: 0;
		margin-bottom: 0;
		position: relative;
	}

	.input-field .prefix ~ input,
	.input-field .prefix ~ textarea,
	.input-field .prefix ~ label,
	.input-field .prefix ~ .validate ~ label,
	.input-field .prefix ~ .helper-text,
	.input-field .prefix ~ .autocomplete-content {
		margin-bottom: 0px;
	}

	i.material-icons {
		color: $cobalt-blue;
	}

	.sec-title {
		position: relative;
		i {
			font-size: 16px;
			position: absolute;
			color: $squash;
			cursor: pointer;
		}
	}

	.fields-cont > div {
		margin-top: 20px;
	}

	#info-section,
	#modalInfoSection {
		margin-bottom: 35px;
		h6 {
			margin-top: 20px;
		}
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
			0 1px 5px 0 rgba(0, 0, 0, 0.2);
		background-color: $off-white;
		min-height: 150px;
		border-top: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-left: 1px solid #ddd;
		margin-top: 0.5rem;
		i.info {
			font-size: 30px;
			margin-top: 12px;
			float: right;
			color: $squash;
		}
		.i-info {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.material-icons {
				font-size: 35px;
			}
		}
	}

	#keySections {
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
			0 1px 5px 0 rgba(0, 0, 0, 0.2);
		background-color: $off-white;
		@media #{$medium-and-down} {
			margin-top: 15px;
			margin-bottom: 15px;
			line-height: 0.5;
			justify-content: space-between;
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 15px;
			h6 {
				width: 100%;
			}
		}
		@media #{$large-and-up} {
			height: 278px;
			height: 276px;
			max-height: 276px;
		}
		@media #{$extra-large-and-up} {
			height: 281px;
			max-height: 281px;
		}
		border-top: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-left: 1px solid #ddd;
		margin-top: 0.5rem;
		h6 {
			margin-top: 19px;
		}
		.keyInfo {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 188px;
			@media #{$large-and-up} {
				width: 100%;
			}
			i {
				margin-right: 15px;
			}
		}
	}

	.info-icon {
		position: absolute;
		right: 0;
		color: $squash !important;
	}

	.cont-checkbox {
		height: inherit;
		padding: 0px;
		width: 100%;
		display: flex;
		justify-content: left;
		vertical-align: middle;
		align-items: center;
		p {
			font-size: 1rem;
			width: 80%;
			margin-top: 0px;
		}
		label {
			height: 20px;
		}
	}

	.error {
		border-bottom: 1px solid $rusty-red;
		p {
			color: $rusty-red !important;
			font-size: 1rem;
		}
		p,
		.switch {
			padding-bottom: 6px;
		}
	}

	.skipped {
		border-bottom: 1px solid $squash;
		p {
			color: $squash !important;
			font-size: 1rem;
		}
		p,
		.switch {
			padding-bottom: 6px;
		}
	}

	.skippedBed {
		border: 1px solid $squash;
		.bed-title {
			color: $squash;
		}
	}

	.error.txt-field {
		border-bottom: none;
		p,
		.material-icons {
			color: $rusty-red !important;
		}
		p {
			font-size: 1rem;
			padding-bottom: 6px;
		}
		textarea.materialize-textarea,
		input[type=number],
		input[type=text],
		input[type=email] {
			border-bottom: 1px solid $rusty-red !important;
			box-shadow: 0 1px 0 0 $rusty-red !important;
		}
	}

	.skipped.txt-field {
		border-bottom: none;
		p,
		.material-icons {
			color: $squash !important;
		}
		p {
			font-size: 1rem;
			padding-bottom: 6px;
		}
		textarea.materialize-textarea,
		input[type=number],
		input[type=text],
		input[type=email] {
			border-bottom: 1px solid $squash !important;
			box-shadow: 0 1px 0 0 $squash !important;
		}
	}
	.datepicker-calendar {
		input[type=text] {
			border-bottom: none;
		}
		.datepicker-table-wrapper {
			color: $dark-blue;
		}
	}
}

.beds_info_container {
	display: flex;
	justify-content: space-around;
	position: relative;
	flex-wrap: wrap;
	padding: 15px 0 15px;
	border: 1px solid #9e9e9e;
	margin-top: 27px !important;
	.bed-title {
		position: absolute;
		top: -12px;
		left: 10px;
		background-color: white;
		padding: 0 4px 0;
	}
}

.error-beds {
	border-bottom: 2px solid $rusty-red;
}

.radio-cont {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-top: 10px;
	span {
		color: $dark-blue;
	}
}

.switch-label {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	color: $dark-blue;
	.sw-txt {
		width: 80%;
		margin: 0;
	}
}

.check-sect .collapsible-header {
	position: relative;
	.leftItm {
		flex: 1 1 20em;
		align-items: center;
		display: flex;
		justify-content: flex-start;
	}
	i.dropdown {
		width: 35px;
		margin: 0;
		height: 24px;
		display: flex;
		align-items: center;
		font-size: 50px;
	}
}

.check-sect .collapsible-body {
	padding-top: 0;
	padding-bottom: 20px;
}

.collapsible-header.inprogress {
	background: linear-gradient(to right, white, rgba(245, 164, 35, 0.25));
	border-bottom: 2px solid rgba(245, 164, 35, 0.5);
	i.material-icons {
		color: $squash;
	}
}

.collapsible-header.complete {
	background: linear-gradient(to right, white, rgba(135, 173, 81, 0.25));
	border-bottom: 2px solid rgba(135, 173, 81, 0.5);
	i.material-icons {
		color: $asparagus;
	}
}

.collapsible-header.error {
	background: linear-gradient(to right, white, rgba(184, 28, 23, 0.25));
	border-bottom: 2px solid rgba(184, 28, 23, 0.5);
	i.material-icons {
		color: $rusty-red;
	}
}

.date-field {
	position: absolute;
	top: 17px;
	left: 45px;
}

.fixed-info-icon {
	position: fixed;
	bottom: 25px;
	right: 25px;
	z-index: 100;
	color: $squash !important;
	text-shadow: 0 1px 2px #ccc;
	font-size: 2.5rem;
}

#rubbish_dates {
	[type="checkbox"]:checked + span:not(.lever):before {
		border-right: 2px solid $cobalt-blue !important;
		border-bottom: 2px solid $cobalt-blue !important;
	}
}

#onboarding_details {
	.datepicker-table td.is-today {
		color: rgba(0, 0, 0, 0.3) !important;
	}
	span.date-field {
		pointer-events: none;
	}
}

a.modal-trigger {
	cursor: pointer;
}

.saveChecklist {
	display: block;
	@media #{$medium-and-up} {
		float: right;
	}
	& > button {
		width: 100%;
		background-color: $asparagus !important;
		border-radius: 0px;
		height: 48px;
		@media #{$medium-and-up} {
			width: 255px;
			margin-top: 30px;
		}
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
			0 1px 5px 0 rgba(0, 0, 0, 0.2);
		&:visited,
		&:hover,
		&:active,
		&:not(:focus),
		&:not(:active) {
			background-color: $asparagus !important;
			pointer-events: unset;
			cursor: pointer;
		}
		&:disabled {
			background-color: #9f9f9f !important;
			color: $white !important;
			cursor: not-allowed;
			pointer-events: unset;
		}
	}
}

textarea.materialize-textarea {
	min-height: 91px !important;
	@media #{$extra-large-and-up} {
		min-height: 75px !important;
	}
}

textarea.materialize-textarea[name=additional_amenities_faq] {
	min-height: 75px !important;
	@media #{$large-and-up} {
		min-height: 56px !important;
	}
}

textarea.materialize-textarea[name=local_attractions_faq] {
	min-height: 126px !important;
	@media #{$extra-large-and-up} {
		min-height: 110px !important;
	}
}

textarea.materialize-textarea[name=smartlock_faq],
textarea.materialize-textarea[name=boiler_how_to_do],
textarea.materialize-textarea[name=boiler_what_to_do],
textarea.materialize-textarea[name=rubbish_instructions] {
	min-height: 110px !important;
	@media #{$extra-large-and-up} {
		min-height: 90px !important;
	}
}

#loadSubmit.progress {
	background-color: rgba(7, 29, 181, 0.4);
	.indeterminate {
		background-color: rgb(7, 29, 181);
	}
}
