.listings_container {
    display: flex;
    flex-direction: column;
    border: 1px solid #ededed;
    border-radius: 6px;
    margin-top: 20px;
    padding: 15px 10px;
    border-top: 8px solid $cobalt-blue;
    box-shadow: 0px 4px 4px 0px #0000001F;

    @media (min-width: 1055px) {
        flex-direction: row;
    }

    // For when there is only one listings section displayed
    div.listings_section--single:first-of-type {
        width: 100%;
        margin-top: 0;
        margin-left: 0;
    }

    .listings_section {
        &:last-of-type {
            margin-top: 15px;
        }

        @media (min-width: 1055px) {
            width: 50%;

            &:last-of-type {
                margin-top: 0;
                margin-left: 20px;
            }
        }
        @media (min-width: 1275px) {
            &:first-of-type {
                width: 40%;
            }
            &:last-of-type {
                width: 60%;
            }
        }

        .direct_property {
            img {
                max-width: 100%;
            }

            &__title {
                color: black;
                margin: 20px 0 15px;
            }

            &__info {
                display: flex;
                border: #b4b4b4 dotted 1px;
                border-radius: 10px;

                &_section {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    padding: 10px 5px;
                    width: 100%;
                    border-left: #b4b4b4 dotted 1px;

                    &:first-of-type {
                        border-left: none;
                    }
                    p {
                        margin: 0;
                        font-size: 0.8em;
                        text-align: center;
                        color: black;

                        @media (min-width: 375px) {
                            white-space: nowrap;
                        }
                    }
                    svg {
                        height: 24px;
                        max-width: 24px;
                        margin-bottom: 5px;
                    }
                }
            }

            &__city_name {
                display: inline-flex;
                align-items: center;
                margin-bottom: 15px;

                p {
                    margin: 0 0 0 10px;
                    color: #4D4D4D;
                }

                svg {
                    width: 15px;
                    height: 16.5px;
                }
            }
        }

        .direct_booking_listing {
            border: $cobalt-blue dotted 1px;
            border-radius: 10px;
            padding: 15px;

            &__ptk_logo {
                img {
                    width: "120px";
                    height: "30px";
                    position: "relative";
                }
            }

            &__title {
                font-size: 19px;
                color: $dark-blue;
            }

            &__text {
                color: #4D4D4D;
                margin-bottom: 15px;

                a {
                    color: #00035c;
                    text-decoration: underline;
                }
            }
        }

        .other_listings {
            margin-top: 20px;
            padding: 0 16px;

            &__title {
                font-size: 19px;
                color: black;
            }

            &__text {
                color: #4D4D4D;
                margin-bottom: 15px;

                a {
                    color: #00035c;
                    text-decoration: underline;
                }
            }

            &__links {
                display: flex;
                flex-direction: column;

                .other_listing_link {
                    display: grid;
                    grid-template-columns: 95px auto 100px;
                    grid-template-rows: 40px 10px;

                    &:last-of-type {
                        grid-template-rows: 40px 0;
                    }

                    img {
                        justify-self: start;
                        align-self: center;
                        width: 80px;
                        margin-right: 15px;
                    }

                    a {
                        color: #4D4D4D;
                    }
                }
            }
        }

        // Shared by direct_booking_listing && other_listings
        .listing {
            &__link {
                display: flex;
                font-size: 0.8em;

                a {
                    border: 1px solid gray;
                    border-radius: 10px 0 0 10px;
                    font-style: italic;
                    padding: 10px 15px;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    height: 40px;
                    color: #00035c;
                }
            }
            &__button {
                border-radius: 0 10px 10px 0;
                white-space: nowrap;
                height: 40px;
            }
        }
    }
}
