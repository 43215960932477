.trumbowyg-box,
.trumbowyg-editor {
  min-height: 50px;
  margin: 0;
}

.trumbowyg-editor {
  color: black;

  >p {
    margin: 0;
  }
}

.col.s12 {
  >label{
    font-size: 1rem;

    &+div+span {
      position: relative;
      min-height: 0px;
      display: block;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
