.material-tooltip {
    color: $dark-blue;
    max-width: 80%;
	background-color: $off-white;
    border: 1px solid $dark-blue;
    font-size: small;
    border-radius: 5px;
    overflow: unset;

    @media #{$large-and-up} {
        max-width: 30%;
    }
}

.material-tooltip::before, .material-tooltip::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    margin: auto;
}

// Tooltip arrows facing down
.material-tooltip.bottom_arrow::before {
    border-top-color: $dark-blue;
    right: 0;
    bottom: -20px;
    left: 0;
}

.material-tooltip.bottom_arrow::after {
    border-top-color: $off-white;
    right: 0;
    bottom: -18px;
    left: 0;
}

// Tooltip arrows facing up
.material-tooltip.top_arrow::before {
    border-bottom-color: $dark-blue;
    top: -20px;
    right: 0;
    left: 0;
}

.material-tooltip.top_arrow::after {
    border-bottom-color: $off-white;
    top: -18px;
    right: 0;
    left: 0;
}

// Tooltip arrows facing right
.material-tooltip.right_arrow::before {
    border-left-color: $dark-blue;
    top: 0;
    right: -20px;
    bottom: 0;
}

.material-tooltip.right_arrow::after {
    border-left-color: $off-white;
    top: 0;
    right: -18px;
    bottom: 0;
}

// Tooltip arrows facing left
.material-tooltip.left_arrow::before {
    border-right-color: $dark-blue;
    top: 0;
    bottom: 0;
    left: -20px;
}

.material-tooltip.left_arrow::after {
    border-right-color: $off-white;
    top: 0;
    bottom: 0;
    left: -18px;
}
