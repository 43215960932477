@import '../../node_modules/materialize-css/sass/materialize.scss';

/* Color Palette */
$white: #ffffff;
$off-white: #f9f9f9;
$cobalt-blue: #071db5;
$rusty-red: #b81c17;
$squash: #f5a423;
$asparagus: #87ad51;
$dark-blue: #01095a;
$airbnb-red: #ff5a5f;
$grey: #9e9e9e;
/* ********** */

/* Custom Screen Sizes */
$extra-large-screen-up: 1360px !default;
$xxl-and-up: "only screen and (min-width : #{$extra-large-screen-up})" !default;
/* ********** */

/* Custom Screen Sizes */
$mobile_header_height: 54px;
$desktop_header_height: 62px;
/* ********** */

.header {
	box-shadow: none;
	border-bottom: 1px solid #efefef;

	.nav-wrapper {
		text-align: center;
	}

	span {
		color: $cobalt-blue;
	}
}

.language_switcher {
	height: $mobile_header_height;

	@media screen and (min-width : 993px) {
		height: $desktop_header_height;
	}

	.select-container {
		height: 100%;
		width: fit-content;
		float: right;
		display: inline-flex;
		align-items: center;

		span {
			color: $cobalt-blue;
		}

		&:hover {
			span {
				color: $squash;
			}
		}
	}

	select {
		-webkit-appearance: none;
		cursor: pointer;
		color: $cobalt-blue;
		margin: 0;
		height: 100%;
		text-align: right;
		border: none;

		&:hover {
			color: $squash;
		}
	}
}

.preferred_language_toast {
	position: fixed;
	background-color: $off-white;
	color: $dark-blue;
	justify-content: center;
	white-space: nowrap;

	.toast-action {
		color: $cobalt-blue;
		margin: 0;
		text-transform: initial;
		font-size: 1.1rem;

		&:hover {
			color: $squash;
		}
	}

	&--success {
		color: $cobalt-blue;
	}

	@media #{$medium-and-up} {
		right: 10px;
		justify-content: initial;
	}
}

.no-color {
	color: transparent !important;
}

html,
body {
	overflow-y: scroll;
}

body {
	margin: 0;
	padding: 0;
	color: $dark-blue;
}

.hide-on-large {
	display: block;

	@media (min-width: 1201px) {
		display: none;
	}
}

.hide-on-tablet {
	display: none;

	@media (min-width: 1201px) {
		display: block;
	}
}

.border-b {
	border-bottom: 1px solid #ebebeb;
}

.bold {
	font-weight: bold;
}

.error,
.rusty-red {
	color: $rusty-red !important;
}

.dark-blue {
	color: $dark-blue !important;
}

.asparagus {
	color: $asparagus !important;
}

.cobalt-blue {
	color: $cobalt-blue !important;
}

.squash {
	color: $squash !important;
}

.airbnb-red {
	color: $airbnb-red !important;
}

.bg-error {
	background-color: $rusty-red !important;
}

.bg-squash {
	background-color: $squash !important;
}

nav .brand-logo {
	color: $cobalt-blue;
}

.m-auto {
	margin: auto !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-3 {
	margin-bottom: 3px !important;
}

.mb-5 {
	margin-bottom: 5px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-25 {
	margin-top: 25px;
}

.mt-5 {
	margin-top: 5px;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-15 {
	margin-top: 15px;
}

.mt-22 {
	margin-top: 22px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.ml-5 {
	margin-left: 5px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-15 {
	margin-left: 15px;
}

.fs-50 {
	font-size: 0.5em;
}

.fs-75 {
	font-size: 0.75em;
}

.baseline {
	display: flex;
	align-items: baseline;
}

.mr-10 {
	margin-right: 10px;
}

.mr-15 {
	margin-right: 15px;
}

.mx-5 {
	margin-left: 5px;
	margin-right: 5px;
}

.my-neg {
	margin-top: -8px;
	margin-bottom: -8px;
}

.pt-0 {
	padding-top: 0px !important;
}

.p-0 {
	padding: 0px !important;
}

.px-0 {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.pl-0 {
	padding-left: 0px !important;
}

.pt-10 {
	padding-top: 10px !important;
}

.pr-30 {
	padding-right: 30px;
}

.pt-20 {
	padding-top: 20px !important;
}

.pb-20 {
	padding-bottom: 20px !important;
}

.pb-10 {
	padding-bottom: 10px !important;
}

.top {
	vertical-align: top;
}

.no-border {
	border: none !important;
}

.light-bg {
	background-color: $off-white;
}

.fix-display {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-evenly {
	display: flex;
	justify-content: space-evenly;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
}

.display-start {
	@media #{$medium-and-up} {
		display: flex;
		justify-content: flex-start;
	}
}

.btn:not(:focus) {
	background-color: $cobalt-blue;
}

.spacer {
	@media #{$medium-and-down} {
		margin: 0;
	}

	@media #{$medium-and-up} {
		margin: 15px auto 15px;
	}
}

.underline {
	text-decoration: underline;
}

.capitalize {
	text-transform: capitalize;
}

// logo
.logo-mobile,
.logo-desktop {
	margin-right: 23px;
	display: inline-block;
	vertical-align: middle;
	background-position: left center;
	background-repeat: no-repeat;
}

img {
	vertical-align: middle;
}

//
.bodyTable {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.main-container {
	background-color: #ffffff;

	@media #{$medium-and-down} {
		padding-left: auto;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	@media #{$large-and-up} {
		padding-left: 300px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	@media #{$extra-large-and-up} {
		padding-left: 300px;
	}
}

.container {
	width: 100%;

	@media #{$large-and-up} {
		width: 95%;
	}

	@media #{$extra-large-and-up} {
		width: 90%;
	}

	@media #{$xxl-and-up} {
		width: 82%;
	}
}

.full-width {
	width: 100%;
	max-width: unset;
}

.split-line {
	border: 0;
	border-top: 1px solid #ebebeb;
	margin-top: 30px;
}

.full-height {
	height: 100%;
	max-height: unset;
	padding: 0;
}

#toast-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	max-width: fit-content;
	top: 0;
	right: 0;
	left: 0;
	margin: auto;
	pointer-events: none;

	.toast {
		pointer-events: auto;
	}
}

.centered {
	background-color: $squash;
	color: $white;
}

.cardHeader {
	padding: 3px 24px;
	background-color: #ffffff;
	color: $dark-blue;
}

.card-content {
	color: $dark-blue;
}

.card .card-image img {
	border-radius: 0 0 2px 2px;
	object-fit: cover;
}

.card-action {
	color: #071db5;
	font-size: 18px;

	@media #{$extra-large-and-up} {
		font-size: 13.8px;
	}
}

.collapsible-body span {
	display: block;
}

.responsive-mr {
	margin-right: 6px;

	@media #{$medium-and-up} {
		margin-right: 15px;
	}
}

.header a {
	color: $dark-blue;

	&:hover,
	&:focus {
		background-color: transparent !important;
	}
}

.spinner-container {
	position: relative !important;
}

.error-toast {
	color: white;
	background-color: $rusty-red;
}

// hide-el but still visible using js
.own-hide {
	position: absolute !important;
	top: -9999px !important;
	left: -9999px !important;
}

// react-pdf
#pdf {
	width: 100%;

	a {
		margin-left: 10.5px;

		@media #{$large-and-up} {
			margin-left: 0;
		}
	}
}

// modal bottom-sheet
.modal.bottom-sheet {
	max-height: 80%;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:after {
	background-color: $cobalt-blue !important;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:before,
[type="radio"].with-gap:checked+span:after {
	border: 2px solid $cobalt-blue !important;
}

//Override react-select css
.Select-arrow {
	border-color: $dark-blue transparent transparent;
}

.Select-control,
.Select.has-value.Select--single>.Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label {
	color: $dark-blue;
	font-weight: 500;
}

.Select.is-focused:not(.is-open)>.Select-control {
	border-color: $dark-blue;
	box-shadow: inset 0 1px 1px rgba(1, 9, 90, 0.075), 0 0 0 1px rgba(1, 9, 90, 0.1);
}

.Select-option {
	color: $dark-blue;
}

.Select-option.is-selected {
	background-color: $cobalt-blue;
	color: #ffffff;
}

.Select-option.is-focused {
	background-color: #ebf5ff;
	/* Fallback color for IE 8 */
	background-color: rgba(7, 29, 181, 0.08);
	color: $dark-blue;
}

// forecast
.chart-card.line-chart {
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0px;
}

// Override tabs default colors
.tabs.tabs-fixed-width.tab-demo.z-depth-1 {
	background-color: $off-white;
	color: $dark-blue;
}

.tabs .tab a:hover,
.tabs .tab a.active {
	color: $cobalt-blue;
}

.tabs .tab a {
	color: $dark-blue;
}

.tabs .tab a:focus,
.tabs .tab a:focus.active {
	// background-color: rgba($color: $cobalt-blue, $alpha: 0.2);
	background-color: $cobalt-blue;
	color: white;
}

.tabs .indicator {
	background-color: $cobalt-blue;
}

//Override switch color
.switch label input[type=checkbox]:checked+.lever {
	background-color: $cobalt-blue;
}

.switch label input[type=checkbox]:checked+.lever:after {
	background-color: #2d46e5;
}

// remove arrow date input
input[type=date]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	display: none;
}

// override btn
.btn {
	background-color: $cobalt-blue;
	border-radius: 5px;
	text-transform: capitalize;

	&:visited,
	&:hover,
	&:active,
	&:not(:focus),
	&:not(:active) {
		background-color: $cobalt-blue;
	}
}

//overrride datepicker
.datepicker-date-display {
	background-color: $cobalt-blue !important;

	.date-text,
	.year-text {
		font-size: 20px;
	}
}

.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done {
	color: $cobalt-blue !important;
	//display: none!important;
	text-transform: capitalize;
}

.month-prev,
.month-next {
	svg {
		fill: $dark-blue !important;
	}

	&:visited,
	&:hover,
	&:active,
	&:focus {
		background-color: #ccc !important;
		outline: 0 !important;
	}
}

.selects-container input {
	color: $dark-blue !important;
}

.datepicker-table td.is-selected {
	background-color: transparent !important;
	color: $dark-blue !important;
}

.is-today,
.dropdown-content li>span {
	color: $cobalt-blue;
}

i.elevator {
	transform: rotate(90deg) !important;
	width: 27px !important;
}

//override color input focus/active fields
input:focus,
textarea.materialize-textarea:focus {
	border-bottom: 1px solid $cobalt-blue !important;
	box-shadow: 0 1px 0 0 $cobalt-blue !important;
}

input,
textarea {
	font-size: 14px !important;
}

.input-field .prefix.active {
	color: $cobalt-blue !important;
}

//fixed footer form
.preferencesFooter {
	position: fixed;
	bottom: 0;
	right: 0;
	height: 60px;
	border-top: 1px solid #efefef;
	background-color: white;
	z-index: 1000;
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0;

	#clearBtn,
	.clearCheckBtn {
		margin-right: 15px;
		z-index: 1100;
	}

	#settingsBtn,
	.submitCheckBtn {
		margin-right: 30px;
		z-index: 1100;
	}
}

// override checkbox filled-in color
[type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
	border: 2px solid $dark-blue;
}

[type="checkbox"].filled-in:checked+span:not(.lever):after {
	border: 2px solid $cobalt-blue;
	background-color: $cobalt-blue;
}

[type="checkbox"]:checked+span:not(.lever):before {
	border-right: 2px solid #fff !important;
	border-bottom: 2px solid #fff !important;
}

// override multiple select dropdown colors
.multiple-select-dropdown>li label {
	color: $cobalt-blue;

	[type="checkbox"]+span:not(.lever)::after,
	[type="checkbox"]:not(.filled-in)+span:not(.lever)::after {
		border: 2px solid $cobalt-blue;
	}
}

.brand-logo>div>img {
	width: 223px;
	height: 63px;
	position: relative;
}

.specific_month_picker_container {
	.datepicker-modal {
		width: fit-content;

		.datepicker-date-display,
		.datepicker-table-wrapper,
		.datepicker-footer {
			display: none;
		}

		.datepicker-controls {
			justify-content: center
		}

		.datepicker-calendar {

			.month-prev,
			.month-next {
				display: none;
			}
		}
	}

	>input {
		text-align: center;
	}
}

.info_btn {
	cursor: help;
}

@import './_navbar.scss';
@import './_login.scss';
@import './_dashboard.scss';
@import './_calendar.scss';
@import './_performance.scss';
@import './_reviews.scss';
@import './_great_host_guide_pages.scss';
@import './_contact_us_and_profile.scss';
@import './_not_found.scss';
@import './_spinner.scss';
@import './_photos.scss';
@import './_preferences.scss';
@import './_checklist.scss';
@import './_dynamic_checklist.scss';
@import './_terms_and_conditions.scss';
@import './_direct_debit.scss';
@import './_listings.scss';
@import './_month_from_to_styles.scss';
@import './_materialize_tooltips_customisation.scss';
@import './_trumbowyg.scss';
@import './_buttons.scss';
