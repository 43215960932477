#month_from_to_selector {
    @media #{$medium-and-up} {
        display: flex;
    }

    .form-container {
        justify-content: center;

        @media #{$medium-and-up} {
            width: 50%;
        }

        input:hover {
            cursor: pointer;
        }

        .invalid_month {
            pointer-events: none;
            color: lightgray;
        }
    }

    >.form-container:first-child {
        margin-bottom: 20px;

        @media #{$medium-and-up} {
            margin-bottom: unset;
        }
    }

    .calendar-container {
        width: 100%;

        @media #{$large-and-up} {
            width: 273px;
        }
    }
}

.datepicker_invalid_date {
    display: none;
}
