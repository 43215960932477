.preferences {
  &-notifications {
    margin-top: 25px;
  }

  .btn {

    &:visited,
    &:hover,
    &:active {
      background-color: $cobalt-blue;
    }
  }

  .checked {
    float: right;
    margin: -11px -26px;

    @media #{$medium-and-up} {
      margin: -16px -25px;
    }
  }

  #hasslefree,
  #getinvolved {
    border: 1px solid $off-white;
    box-shadow: 6px 6px 6px $off-white;
  }

  #hasslefree,
  #getinvolved,
  .height-txt {
    cursor: pointer;
    pointer-events: auto;
  }

  #hasslefree:active,
  #getinvolved:active {
    background-color: rgba($color: $cobalt-blue, $alpha: 0.025);
  }

  .option-container {
    border: 1px solid $off-white;
    box-shadow: 6px 6px 6px $off-white;
    padding: 5px 15px 0;
    margin: 18px 5px;
    min-height: 305px;

    @media (min-width: 425px) and (max-width: 1265px) {
      min-height: 255px;
      margin: 35px;
    }

    @media #{$medium-and-up} {
      min-height: 360px;
    }

    @media (min-width: 1200px) and (max-width: 1265px) {
      min-height: 330px !important;
    }

    @media #{$extra-large-and-up} {
      min-height: 265px;
    }

    &-profile {
      min-height: 170px;
    }
  }

  .selected-opt {
    background-color: rgba($color: $cobalt-blue, $alpha: 0.025);
    box-shadow: 0px 1px 15px -2px $dark-blue !important;
  }

  .form-container {
    min-height: 500px;
  }

  label {
    font-size: inherit;
  }

  label li {
    list-style-type: circle;
    list-style-position: inside;
    color: $dark-blue;
  }

  .split {
    box-shadow: 0 1px 0 0 #ebebeb;
  }

  .title {
    font-size: 19px;
    color: $dark-blue;
    margin: 25px 0 15px;
  }

  .fix-height {
    @media #{$medium-and-up} {
      min-height: 250px;
    }
  }

  .height-txt {
    @media #{$medium-and-up} {
      height: 290px;
    }

    @media (min-width: 1200px) and (max-width: 1265px) {
      height: 260px !important;
    }

    @media #{$extra-large-and-up} {
      height: 185px;
    }
  }

  .switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px;
  }

  .contact-container {
    margin-top: 30px;
    flex: 1 100%;
    display: block;

    @media #{$medium-and-up} {
      display: flex;
      flex-wrap: wrap;
    }

    @media #{$large-and-up} {
      display: flex;
    }

    .input-field {
      flex-grow: 1;
    }

    label {
      font-size: 0.8rem;
    }

    #addContact.btn,
    #editBtn {
      background-color: $cobalt-blue;
      text-transform: capitalize;
      height: 40px;
      border-radius: 0px;
      width: 100%;
    }

    .btn:disabled {
      background-color: rgba(0, 0, 0, 0.3) !important;
      color: white !important;
    }

    .input-field>label:not(.label-icon).active {
      transform: translateY(-14px);
    }

    .select-wrapper+label {
      top: -28px;
    }

    .input-field input {
      border: 0.7px solid rgba(151, 151, 151, 0.4) !important;
      padding: 0 6px !important;
      box-sizing: border-box !important;
      height: 40px !important;
      width: 100% !important;

      &:focus {
        border-bottom: 0.7px solid rgba(7, 29, 181, 0.7) !important;
        box-shadow: 0 0.7px 0 0 rgba(7, 29, 181, 0.7) !important;
      }
    }

    ul li.disabled {
      color: rgba(0, 0, 0, 0.3) !important;
    }

    .dropdown-content li:not(.disabled)>span {
      color: $cobalt-blue;
    }

    .select-dropdown li.disabled {
      color: rgba(0, 0, 0, 0.3) !important;
    }

  }

  a {
    color: $cobalt-blue;
  }

  #deleteContact,
  #editContact {
    cursor: pointer;

    &:focus,
    &:active,
    &:hover {
      color: red;
    }
  }

  #editContact {
    margin-right: 30px;
  }

  .modal-content {
    #editContact {
      margin-right: 0;

      #edit_contact {
        display: block;
        margin-top: 0;

        >button {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .btn-container {
    .btn {
      text-transform: capitalize;

      &:visited,
      &:hover,
      &:active {
        background-color: $cobalt-blue;
      }
    }

    display: flex;
    justify-content: flex-end;
  }

}

form p:last-child {
  &.manager-message {
    margin-bottom: 15px;
  }
}

.option-panel-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media screen and (min-width: 601px) {
    flex-direction: row;
  }

  .notification-option-container {
    padding: 0 10.5px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: 601px) {
      margin-bottom: 0;
      padding: 0 15px;
    }

    .notification-option {
      height: 100%;
      box-sizing: border-box;
      position: relative;
      border: 1px solid $off-white;
      box-shadow: 6px 6px 6px $off-white;
      padding: 15px;

      label {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      p {
        margin: 0 0 15px 0;
      }

      h5 {
        font-size: 19px;
        color: $dark-blue;
        margin: 0 0 15px 0;
      }

      .radio-container {
        margin-top: auto;
      }

      &-checked {
        position: absolute;
        right: -10px;
        top: -10px;
      }

      &-selected {
        background-color: rgba($color: $cobalt-blue, $alpha: 0.025);
        box-shadow: 0px 1px 15px -2px $dark-blue !important;
      }
    }
  }
}

.notification-switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 30px;
  }

  p {
    margin: 0;
  }
}

.notification-button-container {
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}
