#direct-debit {
	#dd-btn.btn {
		margin: 23px auto 25px;
		background-color: #eeeeee !important;
		color: $cobalt-blue;
		text-align: center;
		width: fit-content;
		display: flex;
		vertical-align: middle;
		cursor: pointer;
		span {
			height: 24px;
		}
	}
	.progress {
		background-color: rgba($squash, 0.2);
		margin: 25px auto 25px;
	}
	.indeterminate {
		background-color: $squash;
	}
	.completed, .failed {
		display: flex;
		align-items: center;
		i {
			font-size: 2rem;
		}
	}
	.completed {
		line-height: 1;
	}
	.completed i {
		color: $asparagus;
	}
	.failed i {
		color: $rusty-red;
	}
	.failed {
		cursor: pointer;
		> div {
			display: flex;
			align-items: center;
			strong {
				line-height: 1;
				display: initial;
				color: $rusty-red;
			}
			span {
				display: flex;
			}
		}
	}
}

.in_progress {
	color: $squash;
	border-bottom: 1px solid $squash;
}
.initial {
	border-bottom: none;
}

#direct-debit.not-click {
	#dd-btn.btn, .failed {
		cursor: default;
		line-height: 1;
	}
}

#direct-debit.not-click.in_progress {
	color: $dark-blue;
	border-bottom: none;
}
