.reviews {
  .average-rating {
    color: rgb(236, 154, 58);
    width: fit-content;
    position: relative;
    @media #{$medium-and-up} {
      width: initial;
      margin-top: 31px;
      text-align: right;
    }
    h5 > span {
      margin-right: 16px;
    }
    .icon-star {
      position: absolute;
      top: -2px;
      right: 0;
    }
  }
  .name {
    font-size: 18px;
    font-weight: 500;
  }
  .date {
    font-weight: 500;
    letter-spacing: -0.1px;
  }
}
