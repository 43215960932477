.great-host-guide-pages {
  .great-host-guide-card:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.5);
  }
  width: 100%;
  margin: auto;
  text-align: left;
  h5 {
    color: #071db5;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 25px;
  }
  p {
    margin-top: 0px;
  }
  ul {
    padding-left: 20px!important;
  }
  ul li {
    list-style-type: square!important;
  }
  .top-img-container, .img-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 40vw;
    @media #{$medium-and-up} {
      height: 30vw;
    }
    @media #{$large-and-up} {
      height: 26vw;
    }
    .top-img {
      width: 100%;
    }
  }
  .img-container {
    height: 30vw;
    @media #{$medium-and-up} {
      height: 33vw;
    }
    @media #{$large-and-up} {
      display: block;
      margin: 30px auto auto;
      height: auto;
      max-width: 1000px;
    }
  }

  .guest-communication {
    padding-bottom: 9vw;
    @media #{$medium-and-up} {
      padding-bottom: 19vw;
    }
    @media #{$large-and-up} {
      padding-bottom: 12vw;
    }
  }
  .money-matters {
    padding-bottom: 8vw;
    @media #{$medium-and-up} {
      padding-bottom: 18vw;
    }
    @media #{$large-and-up} {
      padding-bottom: 12vw;
    }
  }
  .partnership {
    padding-bottom: 22vw;
    @media #{$medium-and-up} {
      padding-bottom: 30vw;
    }
    @media #{$large-and-up} {
      padding-bottom: 26vw;
    }
  }
}
