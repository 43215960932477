.logout {
  background-color: rgba(7, 29, 181, 0.05);

  @media #{$small-and-down} {
    margin-bottom: 0 !important;
  }
}

.login,
.logout {
  #error {
    padding-bottom: 20px;
    color: #b81c17;
  }

  .login-form,
  .reset_pw_form,
  .reset-password-confirm,
  .change_pw_form,
  .change_password {
    position: relative;

    .input-field:first-of-type {
      margin-top: 25px;
    }

    .input-field input {
      border-radius: 5px !important;
      border: 0.7px solid rgba(151, 151, 151, 0.4) !important;
      padding: 0 15px !important;
      box-sizing: border-box !important;
      height: 50px !important;
      width: 100% !important;

      &:focus {
        border-bottom: 0.7px solid rgba(7, 29, 181, 0.7) !important;
        box-shadow: 0 0.7px 0 0 rgba(7, 29, 181, 0.7) !important;
      }
    }

  }

  .password-criteria {
    display: inline-block;

    &-text {
      margin-top: 0;
      text-align: left;
    }

    &-list {
      padding-left: 20px;

      li {
        text-align: left;
        list-style-type: disc;
      }
    }
  }


  .title {
    font-size: 18px;
    font-weight: 500;
    margin: 23px auto;
  }

  text-align: center;
  width: 100%;
  background-color: #ffffff;

  @media #{$medium-and-up} {
    margin-top: 30px;
    width: 488px;
    min-height: 73px;
    border-radius: 6px;
    border: 1px solid #efefef;
  }

  #loginBtn.btn,
  #resetPWConfirm.btn,
  #resetPW.btn,
  #changePW.btn {
    position: relative;
    background-color: $cobalt-blue;
    width: 100%;
    border-radius: 5px;
    height: 50px;
  }

  .btn {

    &:visited,
    &:hover,
    &:active {
      background-color: $cobalt-blue;
    }
  }

  .split-line {
    border-bottom: 1px solid #efefef;
  }
}

// Utility class
.text-center {
  text-align: center !important;
}
