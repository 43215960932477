#modalTS.modal {
  width: 95%;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 98%;
  width: min-content;
  min-width: 90%;

  @media #{$medium-and-up} {
    min-width: 50%;
  }
  @media #{$large-and-up} {
    min-width: 30%;
  }

  .close {
    position: absolute;
    right: 3px;
    top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: $cobalt-blue;
    text-align: center;
    padding-top: 3px;
  }
  .datepicker-modal {
    overflow-y: scroll;
    width: 100%;
  }
}

.specific_month_picker_container>.modal.datepicker-modal {
  top: 30%!important; // needed to overwrite styles applied directly to element
}

.modal.datepicker-modal {
  top: 1%!important;
  @media #{$medium-and-up} {
    top: 10%!important;
  }
}

.error {
  color: $rusty-red;
}

.error-toast {
  height: 30px;
  line-height: 1.1;
  word-break: break-word;
  @media #{$medium-and-up} {
    border-radius: 30px;
  }
}

.note {
  cursor: pointer;
  position: absolute;
  padding: .2rem;
  top: 0%;
  @media (min-width: 1201px) {
    padding: .5rem;
    top: 3%;
  }
  right: 0%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.container > .row {
  position: relative;
}

.calendar {
  border: 1px solid #ededed;
  border-radius: 6px;
  margin-top: 20px;
  @media (max-width: 1200px) {
    min-height: auto;
    padding: 18px 6px 14px;
  }
  @media (min-width: 1201px) {
    min-height: 590px;
    padding: 18px 18px 14px;
    box-shadow: 3px 4px 4px 0px rgba(220,220,220,0.7);
  }
  contain: content;
  border-top: 8px solid $cobalt-blue;
  .calendar_header {
    display: flex;
    justify-content: space-between;
    button {
      border-color: transparent;
      cursor: pointer;
      padding: 6px;
    }
    .arr-contain {
      width: 154px;
      text-align: right;
    }
    button:focus, button:hover {
      background-color: #ededed;
    }
    button.dropdown-trigger {
      border-radius: 8px;
      width: 154px;
      text-align: left;
    }
    .left-arrow {
      border-bottom-left-radius: 50%;
      border-top-left-radius: 50%;
    }
    .right-arrow {
      border-bottom-right-radius: 50%;
      border-top-right-radius: 50%;
    }
    #clear-selected {
      color: $dark-blue;
      background-color: #ededed;
      border-radius: 8px;
    }
    .dropdown-content {
      max-height: 500px;
    }
    .dropdown-content li > a {
      color: $cobalt-blue;
    }

    .left-arrow, .right-arrow {
      svg {
        pointer-events: none;
      }
    }

  }

  .calendar_tiles {
    .header_days {
      display: flex;
      justify-content: space-between;
      li {
        width: 20%;
        padding: .1em;
        opacity: .9;
      }
    }
    .calendar_tiles_row {
      height: 20%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      box-sizing: border-box;
      >div {
        flex: 1 1 calc(100%/7);
        height: 75px;
        border-top: 1px solid rgb(220, 220, 220);
        border-left: 1px solid rgb(220, 220, 220);
        background: #ffffff;
        box-sizing: border-box;
        position: relative;

        &[weekday='6'] {
          border-right: 1px solid rgb(220, 220, 220);
        }
        &.faded:not(.selected) {
          border-top: 1px solid rgba(220, 220, 220, .3)!important;
          border-left: 1px solid rgba(220, 220, 220, .3)!important;
        }
        &.faded:not(.selected)[weekday='6'] {
          border-right: 1px solid rgba(220, 220, 220, .3)!important;
        }

        li {
          height: 100%;
          width: 100%;
        }
      }

      .calendar_number {
        padding: 0.3em;
        @media #{$medium-and-up} {
          padding: .5em;
        }
        position: absolute;
      }
      .faded:not(.selected) .calendar_number {
        color: rgba(1, 9, 90, .3)!important;
      }
    }
    .body_days {
      height: 100%;
      width: 100%;
      border-bottom: 1px solid rgb(220, 220, 220);
      position: relative;
    }
    .body_days.selecting  {
      border-bottom: 1px solid rgba(220, 220, 220, 0.4)!important;
    }
  }
  @keyframes pop{
    25% {transform: scale(1.1);}
  }

  .selected {
    li {
      transition: .2s;
      box-shadow: 3px 3px 10px 1px rgba(0,0,0,0.1) inset!important;
      animation: pop 0.3s;
      opacity: 1;
    }
  }

  .unavailable {
    background-color: rgb(229, 229, 229)!important;
  }

  .available {
    background-color: rgb(255, 255, 255)!important;
  }

  .faded:not(.selected) {
    .unavailable {
      background-color: rgba(229, 229, 229, .3)!important;
    }

    .available {
      background-color: rgba(255, 255, 255, .3)!important;
    }
  }

  .faded {
    .worm {
      opacity: 0.4;
    }
    &.selected {
      .worm {
        pointer-events: none;
        &.worm--clean {
          pointer-events: all;
        }
      }
    }

    .selected_worm {
      opacity: 1;
    }
  }

  .light_grey {
    opacity: 0.5;
  }

  .is_past {
    cursor: not-allowed;
  }

  .loading {
    position: absolute;
    background: rgba(252, 252, 252, .6) url('./gif/spinner-mobile.gif') center no-repeat;
    width: 100%;
    height: 100%;
    z-index: 100;
    @media #{$medium-and-up} {
      background: rgba(252, 252, 252, .6) url('./gif/spinner-desktop.gif') center no-repeat;
    }
  }

  .past_screen {
    position: absolute;
    cursor: not-allowed;
    background-color: rgba(242, 242, 242, 0.8);
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 2;
  }

  .worm {
    overflow: hidden;
    box-sizing: content-box;
    cursor: pointer;
    height: 20px;
    position: absolute;
    bottom: 10%;
    border-radius: 15px;
    z-index: 1;
    .name {
      pointer-events: none;
      color: #ffffff;
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      width: max-content;
      left: 10px;
      height: fit-content;
      font-size: x-small;
    }
    &.byo_booking {
      background-size: 1px 8px;
      background-image:  repeating-linear-gradient(0deg, #C7DAA9, #C7DAA9 3px, $asparagus 1px, $asparagus);
    }
    &.booking, &.byo_booking {
      background-color: $asparagus;
      .name {
        background-color: $asparagus;
        padding: 2.5px 2.5px;
      }
    }
    &.superblock, &.host_use {
      background-color: $cobalt-blue;
      .name {
        padding: 2.5px 2.5px;
      }
    }

    &--start {
      border-bottom-right-radius: unset;
      border-top-right-radius: unset;
    }

    &--end {
      border-bottom-left-radius: unset;
      border-top-left-radius: unset;
      left: -1px;
    }

    &--middle {
      border-radius: unset;
      left: -1px;
    }

    &--clean {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $squash;
      width: 40%;
      left: 29%;
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  li:nth-of-type(2n) .worm {
    &.booking, &.byo_booking {
      border-right: 1px solid $asparagus;
    }
    &.superblock, &.host_use {
      border-right: 1px solid $cobalt-blue;
    }
  }

  li:nth-of-type(2n+1) .worm {
    &.booking, &.byo_booking {
      border-left: 1px solid $asparagus;
    }
    &.superblock, &.host_use {
      border-left: 1px solid $cobalt-blue;
    }
  }
}

.modal .cal-form--error {
  margin-top: unset;
}

.cal-form {
  border: 1px solid #ededed;
  border-top: 8px solid $cobalt-blue;
  border-radius: 6px;
  padding: 18px 18px 14px;
  box-shadow: 3px 4px 4px 0px rgba(220,220,220,0.7);
  @media (max-width: 1200px) {
    border-radius: 0;
    margin: -1px;
  }

  &--error {
    margin-top: 15px;
    border-top-color: red;
  }
}

#edit_cal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1200px) {
    overflow-x: hidden;
  }

  .top > .col > .input-field {
    position: relative;

    span.st, span.ed {
      position: absolute;
      left: 16px;
      right: 0;
      top: 5px;
      bottom: 0;
      margin: auto;
      pointer-events: none;
    }
  }

  textarea.materialize-textarea {
    color: $dark-blue;
    padding: .8rem;
    border-radius: 5px;
    border: .7px solid hsla(0,0%,59%,.4)!important;
    max-height: 200px;
    overflow-y: scroll;
    font-size: 1rem;
    min-height: 80px;

    &:focus {
      border-bottom: 0.7px solid rgba(7, 29, 181, 0.7)!important;
      box-shadow: 0 0.7px 0 0 rgba(7, 29, 181, 0.7)!important;
    }
  }

  .input-field {
    margin-top: 30px;

    & > input {
      border-radius: 5px;
      border: .7px solid hsla(0,0%,59%,.4);
      padding: 0 15px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      height: 32px;
      width: 100%;
      font-size: 1rem;

      &:focus {
        border-bottom: 0.7px solid rgba(7, 29, 181, 0.7)!important;
        box-shadow: 0 0.7px 0 0 rgba(7, 29, 181, 0.7)!important;
      }
    }

    &.col label:not(.active) {
      padding-left: 15px;
      padding-right: 15px;
    }

    & > label {
      margin-top: -8px;
      color: $dark-blue;
    }

    & input:focus + label {
     color: $cobalt-blue!important;
    }

    & > .ld.active {
      width: max-content;
      transform: translate(-10px, -14px) scale(0.8);
    }
  }

  #clear-btn {
    margin-right: 15px;
  }
  #clear-btn, #save-btn {
    height: 40px;

    &:visited, &:hover, &:active, &:focus {
      background-color: $cobalt-blue!important;
      outline: 0!important;
    }
  }

  .disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .cont-checkbox {
		height: inherit;
		padding: 0px;
		width: 100%;
		display: flex;
		justify-content: left;
		vertical-align: middle;
		align-items: center;

		p {
			font-size: 1rem;
			width: 80%;
			margin-top: 0px;
		}

		label {
      padding-right: 0px;
			height: 20px;
		}
	}

  #blocking_reason {
    .blocking_option {
      padding-left: 1.75em;

      & > label {
        color: $dark-blue;

        span {
          line-height: 20px;
          height: 20px;
        }
      }

      & #notes {
        textarea {
          min-height: unset !important;
        }
      }
    }
  }
}

.key-container {
  margin: 20px auto 5px;
  display: flex;
  justify-content: flex-start;

  & > div {
    width: 110px;
    line-height: 1.6;
  }
}
.booked-box, .available-box, .blocked-box, .byo_booked-box, .host_use-box, .cleaning-box {
  width: 22px;
  height: 22px;
  font-size: .9rem;
  border-radius: 6px;

  span {
    margin-left: 30px;
  }
}
.booked-box {
  background-color: #87ad51;
  border: 1px solid #87ad51;
}
.byo_booked-box {
  background-color: #88B04B;
  background-size: 1px 8px;
  background-image:  repeating-linear-gradient(0deg, #C7DAA9, #C7DAA9 3px, #88B04B 1px, #88B04B);
  border: 1px solid #87ad51;
  display: flex;

  abbr {
    margin-left: 30px;
  }
  p {
    margin: 0;
    margin-left: 5px;
  }
}
.host_use-box {
  white-space: nowrap;
  background-color: $cobalt-blue;
  border: 1px solid $cobalt-blue;
}
.cleaning-box {
  background-color: $squash;
  border: 1px solid $squash;

  &--first_row, &--second_row {
    align-items: center;

    >svg {
      position: absolute;
      top: unset !important;
      left: unset !important;
      display: unset !important;
      vertical-align: unset !important;
      padding-left: 3px;
    }
  }

  &--first_row {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }
  &--second_row {
    display: flex;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.available-box {
  background-color: #ffffff;
  border: 1px solid rgb(220, 220, 220);
}
.blocked-box {
  background-color: #e5e5e5;
  border: 1px solid rgb(220, 220, 220);
}


#desk-screen {
  display: none;
  @media (min-width: 1201px) {
    display: block;
    margin-top: 20px;
  }
}

#touch-screen {
  display: block;
  @media (min-width: 1200px) {
    display: none;
  }
}

@-moz-document url-prefix() {
  .material-tooltip::before {
    top: calc(100% + 1.5px);
  }
}

.placeholder_cleaning_icon {
  &--hidden {
    display: none;
  }
}

.tooltip {
  display: inline-block;
  transform: scale(0.8);

  >span {
    position: relative;
    z-index: 1;
    margin: 0 5px;
  }
}

// Done to ensure the scale isn't applied twice
.ld{
  #sd_help, #ed_help {
    display: none;
  }
  &.active {
    .tooltip {
      display: initial;
    }
    #sd_help, #ed_help {
      display: inline-block;
    }
  }
}

.booking_text_box {
	font-size: 16px;
  margin: 0 10px;
  margin-top: 10px;

  &--link {
    text-decoration: underline;
    color: $dark-blue;
    font-weight: bold;
  }
}

.cancel_block_checkbox {
  display: flex;
  font-size: 14px;
  color: $dark-blue;
  cursor: pointer;
}

#cleaning_time {
  display: inline-flex;
  color: $cobalt-blue;
  border: solid 1px $squash;
  border-radius: 10px;
  background-color: $squash;
  padding: 0 10px;

  p {
    margin: 0;
  }
}

.notification_box {
  position: relative;
  border-radius: 5px;
  border: 1px solid rgba(150, 150, 150, 0.4);
  padding: 0 15px;
  width: 100%;
  box-shadow: 0px 3px 4px 0px #0000000F;
  margin: 10px 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 9.8%;
    border: 11px solid transparent;
    border-bottom-color: #dddddd;
  }

  &:after {
    left: 10.2%;
    border: 10px solid transparent;
    border-bottom-color: #ffffff;
  }

  p {
    font-size: 0.75rem;
    margin: 0;
  }

  &__guest_stay {
    padding: 10px;

    .close_icon {
      cursor: pointer;
      position: absolute;
      padding-right: 2.5%;
      top: 0;
      right: 0;
    }
  }

  &__confirm_no_clean {
    display: flex;
    margin-bottom: 0;

    p {
      padding: 10px;
    }

    &.arrow_point_no {
      margin-top: 40px;
      padding: 10px;

      &:before {
        left: 58.8%;
      }

      &:after {
        left: 59%;
      }
    }

    .confirmation_option {
      display: flex;
      align-items: center;
      cursor: pointer;
      border: solid 1px $cobalt-blue;
      border-radius: 5px;
      width: 50%;

      &:first-of-type {
        margin-right: 15px;
      }

      p {
        pointer-events: none;
        text-align: center;
        line-height: 1rem;
      }

      &--selected {
        color: $white;
        background-color: $cobalt-blue;
      }
    }
  }
}

#availability-form {
  p {
    font-size: 0.8rem;
  }

  [type="radio"]:not(:checked) + span, [type="radio"]:checked + span {
    font-size: 0.8rem;
  }
}

#end_date_cont, #start_date_cont {
  margin-bottom: 0;
  padding: 0;
}
#start_date_cont:hover {
  z-index: 1;
}

.form_cleaning {
  &__title {
    width: max-content;
    margin-top: 5px;
    font-size: 0.8rem;
  }
}

.form_buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 0.75rem;
}

#cal-container {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
