.navbar-container {
  .navbar-fixed {position:fixed}
  .addOpacity {
    opacity: 0.4;
    background-color: $white;
    border-bottom: none;
  }

  box-shadow: none;
  background-color: #fff;
  .nav-wrapper {
    background-color: #fff;
    border: solid 1px #efefef;
  }

  .sidenav, .dropdown-content {
    .select-property, .listOpt {
      box-shadow: 0 1px 0 0 #ebebeb;
    }
    top: 64px;
    background-color: $off-white;
    border-right: solid 1px #ebebeb;
    box-shadow: none;
    li>a {
      height: 52px;
      line-height: 52px;
      color: $dark-blue;
      font-size: 18px;
      letter-spacing: -0.1px;
      font-weight: 500;
      padding-right: 15px;
      &:hover {
        background-color: $cobalt-blue;
        color: $white;
      }
      span {
        margin-left: 23px;
      }
    }
    li>a.active {
      background-color: $cobalt-blue;
      color: $white;
    }
    .select-property {
      .collapsible {
        margin: 0;
      }
      .collapsible-header {
        position: relative;
        padding: 11.5px 30px;
        line-height: 1.6;
        .arrow-container {
          width: 50%;
          height: 50%;
          overflow: hidden;
          margin: auto;
          position: absolute;
          top: 0;
          right: 11px;
          bottom: 0;
          height: 26px;
        }
        p {
          width: 167px;
        }
      }
      span, p  {
        margin: 0 0 0 23px;
        color: $dark-blue;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.1px;
        cursor: pointer;
        position: relative;
      }
      .tick {
        // width: 50%;
        overflow: auto;
        margin: auto;
        position: absolute;
        top: 0;
        right: 11px;
        bottom: 0;
        height: 65px;
      }
      .collapsible-body .option {
        padding: 2px 30px;
        box-shadow: 0 1px 0 0 #ebebeb;
        margin-left: 0px;
        position: relative;
        min-height: 52px;
        display: flex;
        align-items: center;
        p {
          width: 248px;
          margin-left: 0px;
          line-height: 1.6;
        }
      }
    &:hover {
      background-color: transparent;
    }
    }
  }

  .dropdown-content {
    li>a {
      line-height: initial;
      border-bottom: 1px solid #ebebeb;
    }
  }

  .listOpt {
    a:hover path, a.active path {
      fill: $white!important;
    }
  }

  .nav-item-inset a {
    padding-left: 52px;
  }

  a#Reviews:hover path, a#Reviews.active path {
    fill: $cobalt-blue!important;
    stroke: $white!important;
  }

  .logo_container {
    height: 100%;

    * {
      height: 100%;
    }

    &__logo {
      height: 82%;
    }
  }

  .nav-logo {
    &:hover {
      background-color: transparent!important;
    }
  }

  .nav-logo.desktop {
    padding-left: 30px;
    &:hover {
      background-color: transparent;
    }
  }

  a.menu {
    &:hover path {
      fill: $cobalt-blue!important;
    }
  }

  .dropdown-content.active, .dropdown-content {
    top: 55px!important;
    left: 0!important;
    right: 0!important;
    width: 100%!important;
    li>a {
      padding-left: 30px;
    }
  }
  #mobile-navbar {
    background-color: $white;
    max-height: 100vh;

    &.dropdown-content {
      transform: none !important;
    }

    .select-property {
      .collapsible-body {
        padding: 0!important;
        background-color: $white;
        .option {
          padding: 15.5px 30px;
        }
      }
    }
  }

  .mobile_logo {
    height: $mobile_header_height;

    ul {
      display: inline-block;
    }

    @media screen and (min-width:601px) {
      height: $desktop_header_height;
    }
  }
}
