// big spinner
.big-spinner {
  position: absolute!important;
  z-index: 10000;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 0px 5000px #ffffff;
  background-color: #ffffff;
}

.small-spinner {
  position: absolute!important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

// For preloader spinner
.spinner-orange-only {
  border-color: $squash;
}
