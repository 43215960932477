#termsCondModal.open {
	z-index: 3333333333333;
	display: flex !important;
	justify-content: center !important;
	flex-direction: column !important;
	align-self: center !important;
	line-height: auto;

	@media #{$medium-and-down} {
		min-height: 83% !important;
		height: 83 !important;
		width: 90% !important;
	}

	p {
		line-height: 25px;
	}

	.close {
		cursor: pointer;
		position: absolute;
		margin: 5px 5px 0px;
		right: 0px;
		top: 0;
		bottom: 0;
		width: 26px;
		height: 26px;
		border-radius: 100%;
		background-color: $cobalt-blue;
		z-index: 1;

		svg {
			position: absolute;
			right: 0px;
			margin: auto;
			bottom: 0;
		}
	}

	.tc-title {
		font-size: 1.6rem;
		margin: 8px auto;
		width: 95%;

		@media #{$medium-and-up} {
			width: 86.1%;
			font-size: 1.9rem;
			margin: 10px auto;
		}

		max-height: 155px;
		text-align: center;
		color: $cobalt-blue;
		height: fit-content;
		position: relative;

		p {
			font-size: 14px;
		}
	}

	.modal-content {
		max-height: calc(100vh - 250px);

		@media #{$large-and-up} {
			max-height: calc(100vh - 330px);
		}

		padding: 12px;
		overflow-y: scroll;
		overflow-x: hidden;
		width: 95%;

		@media #{$medium-and-up} {
			width: 86.1%;
		}

		margin: 0 auto;
	}

	.modal-footer {
		padding: 0px;
		margin: 10px auto 0px;
		width: 80%;
		margin-bottom: 5px;

		@media #{$medium-and-up} {
			margin: 10px auto 8px;
		}

		@media screen and (max-width: 600px) {
			display: flex;
			flex-direction: column;
			height: 112px;
			align-items: center;
		}

		.secondary-button {
			margin-top: 6px;
			margin-bottom: 6px;
		}
	}

	.fd {

		button#agreeTC.large,
		button#laterTC.large {
			width: fit-content;
		}

		button#agreeTC {
			width: 95px;
			margin-top: 10px;

			@media #{$medium-and-up} {
				margin-top: 6px;
				margin-left: 30px;
			}
		}

		button#laterTC {
			width: 95px;
		}

		#printBT {
			width: auto;
			position: absolute;
			right: 5px;
		}
	}

	/*** scrollbar ***/

	::-webkit-scrollbar {
		display: inherit;
		appearance: none;
		width: 6px;
	}

	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 9px #ccc !important;
		border-radius: 4px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: $squash !important;
		box-shadow: 0 0 1px rgba(255, 255, 255, .5) !important;
		height: 80px !important;
	}

	/***********/

	// .modal-content {
	// print mode
	/* Google docs css */
	.c59 {
		text-align: center;
	}

	.c4 {
		font-weight: bold;
	}

	ol.lst-kix_list_3-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-2 {
		list-style-type: none;
	}

	.lst-kix_list_3-1>li {
		counter-increment: lst-ctn-kix_list_3-1;
	}

	ol.lst-kix_list_3-0 {
		list-style-type: none;
	}

	.lst-kix_list_3-0>li:before {
		content: "" counter(lst-ctn-kix_list_3-0, decimal) " ";
	}

	ol.lst-kix_list_3-1.start {
		counter-reset: lst-ctn-kix_list_3-1 0;
	}

	ul.lst-kix_list_5-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-8 {
		list-style-type: none;
	}

	.lst-kix_list_3-1>li:before {
		content: "" counter(lst-ctn-kix_list_3-0, decimal) "."
			counter(lst-ctn-kix_list_3-1, decimal) " ";
	}

	.lst-kix_list_3-2>li:before {
		content: "" counter(lst-ctn-kix_list_3-0, decimal) "."
			counter(lst-ctn-kix_list_3-1, decimal) "."
			counter(lst-ctn-kix_list_3-2, decimal) " ";
	}

	ul.lst-kix_list_5-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-6 {
		list-style-type: none;
	}

	.lst-kix_list_4-0>li {
		counter-increment: lst-ctn-kix_list_4-0;
	}

	.lst-kix_list_5-0>li {
		counter-increment: lst-ctn-kix_list_5-0;
	}

	.lst-kix_list_6-0>li {
		counter-increment: lst-ctn-kix_list_6-0;
	}

	ul.lst-kix_list_1-3 {
		list-style-type: none;
	}

	.lst-kix_list_3-5>li:before {
		content: "\002022  ";
	}

	ul.lst-kix_list_1-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-1 {
		list-style-type: none;
	}

	.lst-kix_list_3-4>li:before {
		content: "\002022  ";
	}

	ul.lst-kix_list_1-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-7 {
		list-style-type: none;
	}

	.lst-kix_list_3-3>li:before {
		content: "\002022  ";
	}

	ul.lst-kix_list_5-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-6 {
		list-style-type: none;
	}

	.lst-kix_list_3-8>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_2-0>li {
		counter-increment: lst-ctn-kix_list_2-0;
	}

	.lst-kix_list_3-6>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_3-7>li:before {
		content: "\002022  ";
	}

	ol.lst-kix_list_5-0.start {
		counter-reset: lst-ctn-kix_list_5-0 0;
	}

	.lst-kix_list_3-2>li {
		counter-increment: lst-ctn-kix_list_3-2;
	}

	.lst-kix_list_5-0>li:before {
		content: "" counter(lst-ctn-kix_list_5-0, lower-latin) ") ";
	}

	ol.lst-kix_list_6-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-0 {
		list-style-type: none;
	}

	.lst-kix_list_4-8>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_5-3>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_4-7>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_5-2>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_5-1>li:before {
		content: "\002022  ";
	}

	ul.lst-kix_list_4-8 {
		list-style-type: none;
	}

	.lst-kix_list_5-7>li:before {
		content: "\002022  ";
	}

	ul.lst-kix_list_4-6 {
		list-style-type: none;
	}

	.lst-kix_list_5-6>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_5-8>li:before {
		content: "\002022  ";
	}

	ul.lst-kix_list_4-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-1 {
		list-style-type: none;
	}

	.lst-kix_list_5-4>li:before {
		content: "\002022  ";
	}

	ul.lst-kix_list_4-4 {
		list-style-type: none;
	}

	.lst-kix_list_5-5>li:before {
		content: "\002022  ";
	}

	ul.lst-kix_list_4-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-0.start {
		counter-reset: lst-ctn-kix_list_1-0 0;
	}

	.lst-kix_list_6-1>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_6-3>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_6-0>li:before {
		content: "" counter(lst-ctn-kix_list_6-0, lower-latin) ") ";
	}

	.lst-kix_list_6-4>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_3-0>li {
		counter-increment: lst-ctn-kix_list_3-0;
	}

	ol.lst-kix_list_4-0.start {
		counter-reset: lst-ctn-kix_list_4-0 0;
	}

	.lst-kix_list_6-2>li:before {
		content: "\002022  ";
	}

	ol.lst-kix_list_3-2.start {
		counter-reset: lst-ctn-kix_list_3-2 0;
	}

	.lst-kix_list_6-8>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_6-5>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_6-7>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_6-6>li:before {
		content: "\002022  ";
	}

	ol.lst-kix_list_5-0 {
		list-style-type: none;
	}

	.lst-kix_list_2-6>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_2-7>li:before {
		content: "\002022  ";
	}

	ol.lst-kix_list_1-0 {
		list-style-type: none;
	}

	.lst-kix_list_2-4>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_2-5>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_2-8>li:before {
		content: "\002022  ";
	}

	ul.lst-kix_list_3-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-0.start {
		counter-reset: lst-ctn-kix_list_3-0 0;
	}

	ul.lst-kix_list_3-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-4 {
		list-style-type: none;
	}

	.lst-kix_list_4-0>li:before {
		content: "" counter(lst-ctn-kix_list_4-0, lower-latin) ") ";
	}

	.lst-kix_list_4-1>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_4-4>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_4-3>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_4-5>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_4-2>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_4-6>li:before {
		content: "\002022  ";
	}

	ol.lst-kix_list_4-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-2 {
		list-style-type: none;
	}

	.lst-kix_list_1-0>li:before {
		content: "" counter(lst-ctn-kix_list_1-0, lower-latin) ") ";
	}

	ul.lst-kix_list_2-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-3 {
		list-style-type: none;
	}

	.lst-kix_list_1-1>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_1-2>li:before {
		content: "\002022  ";
	}

	ol.lst-kix_list_2-0.start {
		counter-reset: lst-ctn-kix_list_2-0 0;
	}

	ul.lst-kix_list_2-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-5 {
		list-style-type: none;
	}

	.lst-kix_list_1-3>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_1-4>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_1-0>li {
		counter-increment: lst-ctn-kix_list_1-0;
	}

	.lst-kix_list_1-7>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_1-5>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_1-6>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_2-0>li:before {
		content: "" counter(lst-ctn-kix_list_2-0, lower-latin) ") ";
	}

	.lst-kix_list_2-1>li:before {
		content: "\002022  ";
	}

	ol.lst-kix_list_6-0.start {
		counter-reset: lst-ctn-kix_list_6-0 0;
	}

	.lst-kix_list_1-8>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_2-2>li:before {
		content: "\002022  ";
	}

	.lst-kix_list_2-3>li:before {
		content: "\002022  ";
	}

	ol {
		margin: 0;
		margin-left: 1em;
		padding: 0;
	}

	.table-box {
		margin-bottom: unset;
	}

	.c13 {
		margin-left: 0pt;
		padding-top: 3.4pt;
		list-style-position: inside;
		text-indent: 45pt;
		padding-bottom: 0pt;
		margin-right: 21.8pt;
	}

	.c7 {
		margin-left: 0pt;
		padding-top: 5.9pt;
		list-style-position: inside;
		text-indent: 45pt;
		padding-bottom: 0pt;
		margin-right: 32.5pt;
	}

	.c12 {
		margin-left: 0pt;
		padding-top: 0pt;
		list-style-position: inside;
		text-indent: 45pt;
		padding-bottom: 0pt;
		margin-right: 20pt;
	}

	.c15 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 15.8pt;
		height: 11pt;
	}

	.c33 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 12.4pt;
	}

	.c43 {
		padding-top: 8.1pt;
		padding-bottom: 0pt;
		margin-right: 16.4pt;
	}

	.c17 {
		padding-top: 0.4pt;
		padding-bottom: 0pt;
		height: 11pt;
	}

	.c1 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		height: 11pt;
	}

	.c47 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 16.9pt;
	}

	.c73 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		text-align: justify;
		margin-right: 12.8pt;
	}

	.c51 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 11.1pt;
	}

	.c40 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 19.1pt;
	}

	.c88 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 20.9pt;
	}

	.c48 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 24.9pt;
	}

	.c68 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 24.9pt;
	}

	.c82 {
		padding-top: 3.4pt;
		padding-bottom: 0pt;
		margin-right: 10.7pt;
	}

	.c67 {
		padding-top: 5.8pt;
		padding-bottom: 0pt;
		margin-right: 21.4pt;
	}

	.c72 {
		padding-top: 5.9pt;
		padding-bottom: 0pt;
		margin-right: 21.3pt;
	}

	.c62 {
		padding-top: 8.1pt;
		padding-bottom: 0pt;
		text-align: justify;
		margin-right: 22.9pt;
	}

	.c24 {
		padding-top: 0.2pt;
		padding-bottom: 0pt;
		height: 11pt;
	}

	.c75 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 23.6pt;
	}

	.c64 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 12.4pt;
	}

	.c45 {
		padding-top: 5.8pt;
		padding-bottom: 0pt;
		margin-right: 11.9pt;
	}

	.c77 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 11.9pt;
	}

	.c66 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 23.8pt;
	}

	.c86 {
		padding-top: 8.1pt;
		padding-bottom: 0pt;
		margin-right: 19.8pt;
	}

	.c35 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 10.7pt;
	}

	.c22 {
		padding-top: 3.4pt;
		padding-bottom: 0pt;
		text-align: justify;
		margin-right: 12.4pt;
	}

	.c18 {
		padding-top: 24pt;
		padding-bottom: 6pt;
		page-break-after: avoid;
		text-align: left;
	}

	.c70 {
		padding-top: 5.8pt;
		padding-bottom: 0pt;
		margin-right: 11.3pt;
	}

	.c32 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 26.5pt;
	}

	.c78 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 24.4pt;
	}

	.c69 {
		padding-top: 8.1pt;
		padding-bottom: 0pt;
		margin-right: 13.8pt;
	}

	.c37 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 15.1pt;
	}

	.c38 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 15.6pt;
	}

	.c63 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 39.4pt;
	}

	.c90 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 21.8pt;
	}

	.c84 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 11.5pt;
	}

	.c9 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 11.6pt;
	}

	.c61 {
		padding-top: 0.1pt;
		padding-bottom: 6pt;
		page-break-after: avoid;
		text-align: left;
	}

	.c80 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 32pt;
	}

	.c57 {
		padding-top: 5.9pt;
		padding-bottom: 0pt;
		margin-right: 12.8pt;
	}

	.c30 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		margin-right: 53.6pt;
	}

	.c34 {
		margin-left: 38pt;
		padding-top: 3.4pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c71 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 21.4pt;
	}

	.c52 {
		padding-top: 8.1pt;
		padding-bottom: 0pt;
		margin-right: 26.6pt;
	}

	.c83 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		text-align: justify;
		margin-right: 21.8pt;
	}

	.c81 {
		padding-top: 3.4pt;
		padding-bottom: 0pt;
		margin-right: 23.4pt;
	}

	.c31 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 16pt;
	}

	.c6 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		height: 11pt;
	}

	.c44 {
		padding-top: 8.1pt;
		padding-bottom: 0pt;
		margin-right: 12.4pt;
	}

	.c74 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 10.5pt;
	}

	.c23 {
		padding-top: 6.2pt;
		padding-bottom: 6pt;
		page-break-after: avoid;
		text-align: left;
	}

	.c39 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 14.7pt;
	}

	.c19 {
		padding-top: 8.1pt;
		padding-bottom: 0pt;
		margin-right: 18.7pt;
	}

	.c54 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		text-align: justify;
		margin-right: 6.8pt;
	}

	.c79 {
		padding-top: 5.9pt;
		padding-bottom: 0pt;
		margin-right: 25.8pt;
	}

	.c89 {
		padding-top: 5.8pt;
		padding-bottom: 0pt;
		margin-right: 14.2pt;
	}

	.c46 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		margin-right: 22.2pt;
	}

	.c87 {
		padding-top: 8.1pt;
		padding-bottom: 0pt;
		margin-right: 11.1pt;
	}

	.c29 {
		padding-top: 4.7pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c42 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c36 {
		padding-top: 0.5pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c76 {
		-webkit-text-decoration-skip: none;
		color: #1155cc;
		text-decoration: underline;
		text-decoration-skip-ink: none;
	}

	.c8 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c59 {
		padding-top: 4.7pt;
		padding-bottom: 0pt;
		text-align: center;
	}

	.c20 {
		padding-top: 8.1pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c49 {
		padding-top: 5.9pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c16 {
		padding-top: 4.8pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c53 {
		padding-top: 3.4pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c65 {
		padding-top: 0.3pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c27 {
		padding-top: 0.1pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c41 {
		padding-top: 5.5pt;
		padding-bottom: 0pt;
		text-align: left;
	}

	.c60 {
		-webkit-text-decoration-skip: none;
		color: #0000ff;
		text-decoration: underline;
		text-decoration-skip-ink: none;
	}

	.c3 {
		text-decoration: none;
		vertical-align: baseline;
	}

	.c56 {
		background-color: #ffffff;
		max-width: 507.5pt;
		padding: 54pt 44pt 60pt 44pt;
	}

	.c58 {
		font-weight: 400;
		font-size: 10pt;
	}

	.c0 {
		font-size: 10pt;
		font-weight: 400;
	}

	.c11 {
		font-weight: 400;
		font-size: 11pt;
	}

	.c14 {
		margin-left: 0pt;
		list-style-position: inside;
		text-indent: 45pt;
	}

	.c4 {
		font-weight: 700;
		font-size: 10pt;
	}

	.c55 {
		margin-left: 32.4pt;
		padding-left: 5.6pt;
	}

	.c85 {
		margin-left: 24.9pt;
		padding-left: -5.4pt;
	}

	.c5 {
		padding: 0;
		margin: 0;
	}

	.c28 {
		margin-left: 38pt;
		text-indent: -38pt;
	}

	.c25 {
		color: inherit;
		text-decoration: inherit;
	}

	.c50 {
		background-color: #ffff00;
	}

	.c26 {
		height: 11pt;
	}

	.c21 {
		font-weight: 700;
	}

	.c2 {
		color: $dark-blue;
	}

	.title {
		padding-top: 24pt;
		color: $dark-blue;
		font-weight: 700;
		font-size: 36pt;
		padding-bottom: 6pt;
		page-break-after: avoid;
		text-align: left;
	}

	.subtitle {
		padding-top: 18pt;
		color: #666666;
		font-size: 24pt;
		padding-bottom: 4pt;
		page-break-after: avoid;
		text-align: left;
	}

	li {
		color: $dark-blue;
		font-size: 11pt;
	}

	p {
		margin: 0;
		color: $dark-blue;
		font-size: 11pt;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 15pt;
		margin-bottom: 15pt;

		span {
			font-size: 11pt;
		}
	}

	h1 {
		color: $dark-blue;
		font-weight: 700;
		font-size: 9pt;
		text-align: left;
	}

	h2 {
		color: $dark-blue;
		font-weight: 700;
		font-size: 9pt;
		page-break-after: avoid;
		text-align: left;
	}

	h3 {
		color: $dark-blue;
		font-weight: 700;
		font-size: 14pt;
		page-break-after: avoid;
		text-align: left;
	}

	h4 {
		color: $dark-blue;
		font-weight: 700;
		font-size: 12pt;
		page-break-after: avoid;
		text-align: left;
	}

	h5 {
		color: $dark-blue;
		font-weight: 700;
		font-size: 11pt;
		page-break-after: avoid;
		text-align: left;
	}

	h6 {
		color: $dark-blue;
		font-weight: 700;
		font-size: 10pt;
		page-break-after: avoid;
		text-align: left;
	}

	/***************/
	// }
}
