.photos {
  .container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #modalPhoto {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .modal-close {
    position: absolute;
  }
  .full-screen-img {
    width: 100%;
  }
  .loading {
      background: transparent url('./gif/spinner-mobile.gif') center no-repeat;
      @media #{$medium-and-up} {
        background: transparent url('./gif/spinner-desktop.gif') center no-repeat;
      }
    }
  .inline-input {
    display: flex;
    @media #{$small-and-down} {
      justify-content: space-between;
    }
    justify-content: flex-end;
    align-items: center;
    & > span {
      font-weight: 500;
      @media #{$small-and-down} {
      margin: 0 6px;
    }
      margin: 0 15px;
    }
    @media #{$small-and-down} {
      margin-top: 10px;
    }
  }
  .custom-select {
    width: 200px;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-content-img-container {
    height: 156px;
    background-color: $off-white;
  }
  .img-container {
    display: flex;
    justify-content: space-between;
    contain: strict;
  }
  .img-box {
    flex-grow: 1;
    margin-right: 12px;
    object-fit: cover;
    height: 95px;
    border-radius: 6px;
    width: 25%;
  }
  .custom-hide-large, .custom-hide {
    @media screen and (min-width:993px) {
      display: none!important;
    }
    @media screen and (min-width:1800px) {
      display: block!important;
    }
  }
  .custom-hide {
    @media screen and (min-width:1100px) {
      display: block!important;
    }
  }
  .last-img-box {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    p {
      position: absolute;
    }
    .img-box {
      opacity: 0.15;
      margin-right: 0;
    }
  }
  #container-photos {
    .room-title {
      font-size: 24px;
      font-weight: 500;
    }
    .room-photos {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-grow: 1;
    }
    .card-image.link {
      cursor: pointer;
    }
    .card-image {
      margin-bottom: 15px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & > img {
        object-fit: cover;
        width: 100%;
        border-radius: 8px;
        height: 28vw;
        @media #{$large-and-up} {
          height: 18vw;
        }
        @media screen and (min-width: 2000px)  {
          height: 15vw;
        }
      }
      .container-lens, .container-lens-mobile {
        background-color: $cobalt-blue;
        border-radius: 8px;
        position: absolute;
      }
      .container-lens {
        width: 35px;
        height: 35px;
        right: 15px;
        bottom: 15px;
      }
      .container-lens-mobile {
        width: 20px;
        height: 20px;
        right: 5px;
        bottom: 5px;
      }
    }
    .split-line-rooms {
      border: 1px solid #ebebeb;
    }
  }
  .title-row {
    & > div {
      height: 50px;
      display: flex;
      align-items: flex-end;
    }
    h5 {
      font-size: 20px;
      @media #{$large-and-up} {
        font-size: 22px;
      }
      @media #{$extra-large-and-up} {
        font-size: 24px;
      }
      margin: 0;
    }
    .back {
      justify-content: flex-start;
    }
    .title {
      justify-content: flex-start;
      @media #{$medium-and-up} {
        justify-content: center;
      }
    }
    .filter-title {
      justify-content: flex-end;
    }
  }
  .arrows-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 20px;
    svg {
      cursor: pointer;
    }
  }
  .carousel.carousel-slider {
    min-height: 230px;
    max-height: 240px;
    @media #{$medium-and-up} {
      min-height: 330px;
      max-height: 340px;
    }
    @media #{$large-and-up} {
      min-height: 390px;
      max-height: 400px;
    }
     @media only screen and (min-width: 1600px) {
      min-height: 590px;
      max-height: 600px;
    }
    .carousel-item {
      width: 100%;
      min-height: 230px;
      max-height: 240px;
      @media #{$medium-and-up} {
        min-height: 330px;
        max-height: 340px;
      }
      @media #{$large-and-up} {
        min-height: 390px;
        max-height: 400px;
      }
      @media only screen and (min-width: 1600px) {
        min-height: 590px;
        max-height: 600px;
      }
      img {
        border-radius: 8px;
        width: 100%;
        object-fit: cover;
        min-height: 230px;
        max-height: 240px;
        @media #{$medium-and-up} {
          min-height: 330px;
          max-height: 340px;
        }
        @media #{$large-and-up} {
          min-height: 390px;
          max-height: 400px;
        }
        @media only screen and (min-width: 1600px) {
          min-height: 590px;
          max-height: 600px;
        }
      }
    }
    & > div {
      cursor: pointer;
    }
  }
  .small-carousel {
    justify-content: flex-start;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    .carousel.carousel-slider {
      height: 100px;
    }
  }

  .carousel-item-small {
    flex: 0 0 auto;
    width: 110px;
    height: 65px;
    @media #{$large-and-up} {
      height: 100px;
      width: 200px;
    }
    opacity: 0.3;
    margin-right: 20px;
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }
  .carousel-item-small.active {
    opacity: 1;
  }
}
